<template>
	<svg width="38" height="30" viewBox="0 0 38 30" 
		fill="none" xmlns="http://www.w3.org/2000/svg"
	>
		<path fill-rule="evenodd" clip-rule="evenodd" 
			d="M37.999 14.9995C33.1856 20.0514 28.4574 25.0252 23.6441 30H14.3548L9.45744 24.8719H18.9989L28.4564 14.9995L26.0928 12.5517L16.6354 22.4231H7.09387L0 14.9995C4.72924 10.0257 9.54151 5.05 14.3548 0H23.6441L28.5415 5.1262H19L9.54256 14.9986L11.9082 17.4474L21.2816 7.57501H30.9082L38 14.9976L37.999 14.9995Z" fill="black"
		/>
	</svg>
</template>

<script>
export default {
	name: "stakingFill"
};
</script>

<style>

</style>