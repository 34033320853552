import axios from 'axios';

let prefix = "/starter-pack"

const MintingPackApi = {
	
	getStarterPackList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	purchaseStarterPack(payload){
		return axios.post( prefix + "/buy", payload)
	},
}

export default MintingPackApi;