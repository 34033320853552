import en from 'vuetify/lib/locale/en'

const nav = {
	"Dashboard": "Dashboard",
	"User Profile": "User Profile",
	"Pairing Network": "Pairing Network",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "Direct Referral",
	"Royalty": "Royalty",
	"Running Royalty": "Running Royalty",
	"1Move Library": "1Move Library",
	"Transactions": "Transactions",
	"News": "News",
	"Starter Pack": "Starter Pack",
	"Features": "Features",
	"Options": "Options",
	"Language Switcher": "Language Switcher",
	"Log Out": "Log Out",
}

const token_transaction = {
	"Token Transactions": "Token Transactions",
	"Type": "Type",
	"Token Type": "Token Type",
	"Amount": "Amount",
	"Created at": "Created at",
}

const starter_pack = {
	"NFT SHOE": "NFT SHOE",
	"Mint": "Mint",
	"Walker": "Walker",
	"Jogger": "Jogger",
	"Runner": "Runner",
	"Selected": "Selected",
	"Total": "Total",
	"Back": "Back",
	"Click to Download Networking Info": "Click to Download Networking Info",
	"This feature is currently unavailable.": "This feature is currently unavailable.",
	"Select one shoe": "Select one shoe",
	"Select three shoes": "Select three shoes",
	"OR": "OR",
	"Minting Pack": "Minting Pack",
}

const royalty = {
	"Royalty Payout": "Royalty Payout",
	"Last updated": "Last updated",
	"Collect Payout": "Collect Payout",
	"Are you sure to continue?": "Are you sure to continue?",
	"Are you sure you want to collect the payout?": "Are you sure you want to collect the payout?",
	"Warning": "Warning"
}

const pairing_tree = {
	"Pairing Tree": "Pairing Tree",
	"Flushing Schedule": "Flushing Schedule",
	"Next Flushing Date": "Next Flushing Date",
	"Team": "Team",
	"Sneakers": "Sneakers",
	"Starter Pack Date": "Starter Pack Date",
	"No Referral": "No Referral",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "Referrals Data",
	"Direct Referral Tree": "Direct Referral Tree",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "No Result Found.",
	"No Starter Pack": "No Starter Pack",
	"Referrals By Month": "Referrals By Month",
	"Referral": "Referral",
	"Left Tree": "Left Tree",
	"Right Tree": "Right Tree",
}

const date = {
	"January": "January",
	"February": "February",
	"March": "March",
	"April": "April",
	"May": "May",
	"June": "June",
	"July": "July",
	"August": "August",
	"September": "September",
	"October": "October",
	"November": "November",
	"December": "December",
}

const home = {
	"Total Referral": "Total Referral",
	"Total Referral (Month)": "Total Referral (Month)",
	"Total Pairing Payout (Month)": "Total Pairing Payout (Month)",
	"Total Direct Referral Payout (Month)": "Total Direct Referral Payout (Month)",
	"Direct Referral Payout": "Direct Referral Payout",
	"Total Royalty Payout (Month)": "Total Royalty Payout (Month)",
	"Welcome Back": "Welcome Back",
	"Your current ranking is": "Your current ranking is",
	"Pairing Payout": "Pairing Payout",
	"Total Accumulated": "Total Accumulated",
	"Direct Referral": "Direct Referral",
	"Royalty Payout": "Royalty Payout",
	"Total Members Under Pairing & Direct Referral Network": "Total Members Under Pairing & Direct Referral Network",
	"Total Starter Pack Under Pairing & Direct Referral Network": "Total Starter Pack Under Pairing & Direct Referral Network",
	"Total Registered Account Under Pairing & Direct Referral Network": "Total Registered Account Under Pairing & Direct Referral Network",
	"Token": "Token",
	"Items Owned": "Items Owned",
	"Artifacts": "Artifacts",
	"Shoeboxes": "Shoeboxes",
	"Referrals By Country": "Referrals By Country",
	"News Feed": "News Feed",
}

const user = {
	"Email": "Email",
	"Name": "Name",
	"Ranking": "Ranking",
	"Left Side Pairing Amount": "Left side Pairing Amount",
	"Right Side Pairing Amount": "Right side Pairing Amount",
	"Referred By": "Referred By",
	"Joined at": "Joined at",
	"Status": "Status",
	"Active": "Active",
	"Deactivated": "Deactivated",
	"Uncalculated": "Uncalculated",
	"Pending": "Pending",
	"Rank": "Rank",
	"Registered On": "Registered On",
	"Activated On": "Activated On",
	"Total Distance": "Total Distance",
	"Total Income": "Total Income",
	"Total Runs": "Total Runs",
	"times": "times",
	"Total Run Time": "Total Run Time",
	"hours": "hours",
}

const misc = {
	"Filters": "Filters",
	"Type": "Type",
	"Apply": "Apply",
}

const user_token = {
	"Next Flushing Date": "Next Flushing Date",
	"Pairing Tree": "Pairing Tree",
	"Flushing Schedule": "Flushing Schedule",
	"No Referral": "No Referral",
	"Receive pairing payout": "Receive pairing payout",
	"Payout Details": "Payout Details",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "Left Sneakers",
	"Right Sneakers": "Right Sneakers",
	"Total Sneakers": "Total Sneakers",
	"Payout in USDT": "Payout in USDT",
	"Payout in 1MT": "Payout in 1MT",
	"USDT to 1RT Conversion Rate": "USDT to 1RT Conversion Rate",
	"1MT to 1RT Conversion Rate": "1MT to 1RT Conversion Rate",
	"1RT Received": "1RT Received",
	"Pairing Carry Forward Details": "Pairing Carry Forward Details",
	"Pairing Flushing Details": "Pairing Flushing Details",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "Staking",
	"Staking Info": "Staking Info",
	"Staking Portfolio": "Staking Portfolio",
	"Staking History": "Staking History",
	"Next Staking": "Next Staking",
	"Buy Now": "Buy Now",
	"Buy Lot": "Buy Lot",
	"Buy Lot Amount": "Buy Lot Amount",
	"Total Amount": "Total Amount",
	"Buy": "Buy",
	"Lots": "Lots",
	"Cancel": "Cancel",
	"Staking Availability Table": "Staking Availability Table",
	"Total 1MT Pool of Funds": "Total 1MT Pool of Funds",
	"Coming Soon": "Coming Soon",
	"Lot Amount": "Lot Amount",
	"Amount Purchased": "Amount Purchased",
	"Maturity Date": "Maturity Date",
	"1RT Earning": "1RT Earning",
	"BNB Earning": "BNB Earning",
	"Total Staking": "Total Staking",
	"Staking Live": "Staking Live",
	"Ends On": "Ends On",
	"OTP Code": "OTP Code",
	"Request OTP": "Request OTP",
	"OTP code sent.": "OTP code sent.",
	"Sold Out": "Sold Out",
	"Sell": "Sell",
	"Sell Lot": "Sell Lot",
	"Available": "Available",
	"Penalty": "Penalty",
	"Final Amount": "Final Amount",
	"1RT Payout": "1RT Payout",
	"Unsubscribe Lot Amount": "Unsubscribe Lot Amount",
	"Unsubscribe Lot": "Unsubscribe Lot",
	"Unsubscribe": "Unsubscribe",
	"BNB Point Breakdown": "BNB Point Breakdown",
	"Month": "Month",
	"Year": "Year",
	"Total Point": "Total Point",
	"Point": "Point",
	"BNB Point Ratio": "BNB Point Ratio",
	"Staking Amount": "Staking Amount",
	"BNB Received": "BNB Received",
}

export default {
	$vuetify: {
		...en
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}