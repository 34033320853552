<template>
	<svg width="30" height="30" 
		viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M15 3.25C8.51065 3.25 3.25 8.51065 3.25 15C3.25 17.6361 4.11742 20.0686 5.58334 22.029C7.0634 19.7469 9.30345 18.063 11.7808 17.2651C10.2537 16.2314 9.25 14.4829 9.25 12.5C9.25 9.32436 11.8244 6.75 15 6.75C18.1756 6.75 20.75 9.32436 20.75 12.5C20.75 14.4829 19.7463 16.2314 18.2192 17.2651C20.6966 18.0629 22.9366 19.7468 24.4167 22.0289C25.8826 20.0685 26.75 17.6361 26.75 15C26.75 8.51065 21.4893 3.25 15 3.25ZM23.3922 23.224C21.5979 20.1666 18.2801 18.25 15.0001 18.25C11.72 18.25 8.40217 20.1666 6.60793 23.2241C8.74139 25.4009 11.7125 26.75 15 26.75C18.2875 26.75 21.2587 25.4008 23.3922 23.224ZM1.75 15C1.75 7.68223 7.68223 1.75 15 1.75C22.3178 1.75 28.25 7.68223 28.25 15C28.25 18.5768 26.8317 21.8239 24.5285 24.2071C22.1201 26.699 18.7405 28.25 15 28.25C11.2595 28.25 7.87986 26.699 5.47149 24.2071C3.16826 21.8239 1.75 18.5768 1.75 15ZM15 8.25C12.6528 8.25 10.75 10.1528 10.75 12.5C10.75 14.8472 12.6528 16.75 15 16.75C17.3472 16.75 19.25 14.8472 19.25 12.5C19.25 10.1528 17.3472 8.25 15 8.25Z" fill="#B8B8B8"/>
	</svg>
</template>

<script>
export default {
	name: "user-default"
};
</script>

<style>

</style>