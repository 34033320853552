import axios from 'axios';

let prefix = "/dashboard"

const DashboardApi = {
	
	getUserPanelDashboard(payload){
		return axios.get( prefix + "/user-panel/get", {params: payload})
	},
}

export default DashboardApi;