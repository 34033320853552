import axios from 'axios';

let prefix = "/referral-code"

const UserTokenApi = {
	
	getUserReferralCodeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default UserTokenApi;