import pt from 'vuetify/lib/locale/pt'

const nav = {
	"Dashboard": "Painel",
	"User Profile": "Perfil do usuário",
	"Pairing Network": "Rede de emparelhamento",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "Patrocinador Direto",
	"Royalty": "Realeza",
	"Running Royalty": "Royalty em Execução",
	"1Move Library": "1Move Biblioteca",
	"Transactions": "Transações",
	"News": "Notícias",
	"Starter Pack": "Pacote de partida",
	"Features": "Opções",
	"Options": "Opções",
	"Language Switcher": "Alterar Idioma",
	"Log Out": "Log Out",
}

const token_transaction = {
	"Token Transactions": "Transação de Token",
	"Type": "Tipo",
	"Token Type": "Tipo de token",
	"Amount": "Quantidade",
	"Created at": "Criado em",
}

const starter_pack = {
	"NFT SHOE": "Sapato NFT",
	"Mint": "Como",
	"Walker": "Andador",
	"Jogger": "Agasalho esportivo",
	"Runner": "Corredor",
	"Selected": "Selecionar",
	"Total": "Total",
	"Back": "Voltar",
	"This feature is currently unavailable.": "O recurso para comprar pacote inicial está atualmente indisponível. Desculpe pelo inconveniente causado.",
	"Click to Download Networking Info": "Clique para baixar informações de rede",
	"Select one shoe": "Selecione um sapato",
	"Select three shoes": "Selecione três sapatos",
	"OR": "OU",
	"Minting Pack": "Pacote de cunhagem",
}

const royalty = {
	"Royalty Payout": "Pagamento de Royalties",
	"Last updated": "Última atualização",
	"Collect Payout": "Recolher pagamento",
	"Are you sure to continue?": "Tem certeza de continuar?",
	"Are you sure you want to collect the payout?": "Tem certeza de que deseja receber o pagamento?",
	"Warning": "Aviso"
}

const pairing_tree = {
	"Pairing Tree": "Árvore de emparelhamento",
	"Flushing Schedule": "Cronograma de descarga",
	"Next Flushing Date": "Próxima data de descarga",
	"Team": "Equipe",
	"Sneakers": "Tênis",
	"Starter Pack Date": "Data do pacote inicial",
	"No Referral": "Sem Referência",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "Dados de referência",
	"Direct Referral Tree": "Patrocinador Direto",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "Nenhum resultado encontrado.",
	"No Starter Pack": "Sem pacote de partida",
	"Referrals By Month": "Referências por mês",
	"Referral": "Referência",
	"Left Tree": "Árvore Esquerda",
	"Right Tree": "árvore direita",
}

const date = {
	"January": "Janeiro",
	"February": "Fevereiro",
	"March": "Março",
	"April": "Abril",
	"May": "Maio",
	"June": "Junho",
	"July": "Julho",
	"August": "Agosto",
	"September": "Setembro",
	"October": "Outubro",
	"November": "Novembro",
	"December": "Dezembro",
}

const home = {
	"Total Referral": "Encaminhamento Total",
	"Total Referral (Month)": "Encaminhamento Total (Mês)",
	"Total Pairing Payout (Month)": "Pagamento total de emparelhamento (mês)",
	"Total Direct Referral Payout (Month)": "Pagamento total de patrocinador direto (mês)",
	"Direct Referral Payout": "Pagamento total de patrocinador direto",
	"Total Royalty Payout (Month)": "Pagamento total de royalties (mês)",
	"Welcome Back": "Bem vindo de volta",
	"Your current ranking is": "Sua classificação atual é",
	"Pairing Payout": "Pagamento de emparelhamento",
	"Total Accumulated": "Total Acumulado",
	"Direct Referral": "Patrocinador Direto",
	"Royalty Payout": "Pagamento de Royalties",
	"Total Members Under Pairing & Direct Referral Network": "Total de membros sob emparelhamento e rede de patrocinador direto",
	"Total Starter Pack Under Pairing & Direct Referral Network": "Total Starter Pack Em Emparelhamento e Rede de Patrocínio Direto",
	"Total Registered Account Under Pairing & Direct Referral Network": "Total de contas registradas em emparelhamento e rede direta do patrocinador",
	"Token": "Símbolo",
	"Items Owned": "Itens de propriedade",
	"Artifacts": "artefatos",
	"Shoeboxes": "caixas de sapatos",
	"Referrals By Country": "Referências por país",
	"News Feed": "Notícias",
}

const user = {
	"Email": "Email",
	"Name": "Name",
	"Ranking": "Classificação",
	"Left Side Pairing Amount": "Quantidade de pareamento do lado esquerdo",
	"Right Side Pairing Amount": "Quantidade de emparelhamento do lado direito",
	"Referred By": "Encaminhado por",
	"Joined at": "Juntou-se em",
	"Status": "Estado",
	"Active": "Ativo",
	"Deactivated": "Desativou",
	"Uncalculated": "não calculada",
	"Pending": "pendente",
	"Rank": "Classificação",
	"Registered On": "Registrado em",
	"Activated On": "Ativado em",
	"Total Distance": "Distância total",
	"Total Income": "Renda total",
	"Total Runs": "Total de execuções",
	"times": "vezes",
	"Total Run Time": "Tempo total de execução",
	"hours": "horas",
}

const misc = {
	"Filters": "Filtros",
	"Type": "Tipo",
	"Apply": "Aplicar",
}

const user_token = {
	"Next Flushing Date": "Próxima data de descarga",
	"Pairing Tree": "Árvore de emparelhamento",
	"Flushing Schedule": "Cronograma de descarga",
	"No Referral": "Sem Referência",
	"Receive pairing payout": "Receber pagamento de emparelhamento",
	"Payout Details": "Detalhes de pagamento",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "Tênis esquerdos",
	"Right Sneakers": "Tênis Certo",
	"Total Sneakers": "Tênis Total",
	"Payout in USDT": "Pagamento em USDT",
	"Payout in 1MT": "Pagamento em 1MT",
	"USDT to 1RT Conversion Rate": "Taxa de conversão USDT para 1RT",
	"1MT to 1RT Conversion Rate": "Taxa de conversão 1MT para 1RT",
	"1RT Received": "1RT Recebido",
	"Pairing Carry Forward Details": "Emparelhamento Levar detalhes para a frente",
	"Pairing Flushing Details": "Emparelhando detalhes de descarga",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "Staking",
	"Staking Info": "Informações de Staking",
	"Staking Portfolio": "Portfólio de Staking",
	"Staking History": "Histórico de Staking",
	"Next Staking": "Próximo Staking",
	"Buy Now": "Comprar Agora",
	"Buy Lot": "Comprar Lote",
	"Buy Lot Amount": "Quantidade de Compra do Lote",
	"Total Amount": "Valor Total",
	"Buy": "Comprar",
	"Lots": "Lotes",
	"Cancel": "Cancelar",
	"Staking Availability Table": "Tabela de Disponibilidade de Staking",
	"Total 1MT Pool of Funds": "Total de 1MT no Pool de Fundos",
	"Coming Soon": "Em Breve",
	"Lot Amount": "Valor do Lote",
	"Amount Purchased": "Valor Comprado",
	"Maturity Date": "Data de Vencimento",
	"1RT Earning": "Ganhos 1RT",
	"BNB Earning": "Ganhos BNB",
	"Total Staking": "Total de Staking",
	"Staking Live": "Staking ao vivo",
	"Ends On": "Termina em",
	"OTP Code": "Código OTP",
	"Request OTP": "Solicitar OTP",
	"OTP code sent.": "Código OTP enviado.",
	"Sold Out": "Esgotado",
	"Sell": "Vender",
	"Sell Lot": "Vender Lote",
	"Available": "Disponível",
	"Penalty": "Penalidade",
	"Final Amount": "Valor Final",
	"1RT Payout": "Pagamento 1RT",
	"Unsubscribe Lot Amount": "Quantidade de Lotes para Cancelar Inscrição",
	"Unsubscribe Lot": "Cancelar Inscrição do Lote",
	"Unsubscribe": "Cancelar Inscrição",
	"Month": "Mês",
	"Year": "Ano",
	"Total Point": "Total de Pontos",
	"Point": "Ponto",
	"BNB Point Ratio": "Proporção de Pontos BNB",
	"Staking Amount": "Valor de Stake",
	"BNB Received": "BNB Recebido",
}

export default {
	$vuetify: {
		...pt
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}