import axios from 'axios';

let prefix = "/user-token-stake"

const UserTokenStakeApi = {
	getUserTokenStakeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getSellUserTokenStakeInfo(payload){
		return axios.get( prefix + "/sell-info", {params: payload})
	},
	sellUserTokenStake(payload){
		return axios.post( prefix + "/sell", payload)
	},
}

export default UserTokenStakeApi;