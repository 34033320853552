import axios from 'axios';

let prefix = "/token-stake"

const TokenStakeApi = {
	getStakingHistory(payload){
		return axios.get( prefix + "/history", {params: payload})
	},
	getActiveTokenStake(payload){
		return axios.get( prefix + "/active-stake", {params: payload})
	},
	getTokenStakeTotalPrice(payload){
		return axios.get( prefix + "/total-price", {params: payload})
	},
	getTokenStakeMaxLot(payload){
		return axios.get( prefix + "/maximum-lot", {params: payload})
	},
	purchaseTokenStake(payload){
		return axios.post( prefix + "/purchase", payload)
	},
}

export default TokenStakeApi;