import axios from 'axios';

let prefix = "/share-build-payout"

const PayoutApi = {
	getBurningRoyaltyPayoutDetail(payload){
		return axios.get( prefix + "/get-burning-royalty-detail", {params: payload})
	},
}

export default PayoutApi;