import axios from 'axios';

let prefix = "/share-build"

const ShareBuildApi = {
	getShareBuildTree(payload){
		return axios.get( prefix + "/list-tree", {params: payload})
	},
}

export default ShareBuildApi;