<template>
	<svg width="38" height="30" 
		viewBox="0 0 38 30" 
		fill="none" 
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M27.0216 4.4762H19H18.7226L18.5306 4.67655L9.07319 14.5489L8.64062 15.0005L9.07508 15.4502L11.4408 17.899L11.9123 18.3871L12.3796 17.8949L21.5608 8.22501H30.6303L37.102 14.9987C36.7005 15.4201 36.2996 15.841 35.8992 16.2613C31.7085 20.6608 27.5701 25.0055 23.3685 29.35H14.6329L10.977 25.5219H18.9989H19.2764L19.4683 25.3215L28.9258 15.4492L29.3582 14.9977L28.924 14.548L26.5604 12.1002L26.091 11.614L25.6235 12.102L16.3579 21.7731H7.37179L0.898096 14.9985C3.29547 12.4797 5.70848 9.96506 8.13254 7.43892C10.2918 5.18877 12.4597 2.92949 14.6332 0.65H23.3661L27.0216 4.4762Z" stroke="#B8B8B8" 
			stroke-width="1.3"
		/>
	</svg>
</template>

<script>
export default {
	name: "stakingIcon"
};
</script>

<style>

</style>