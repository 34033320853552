import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '@/locales/zh'
import en from '@/locales/en'
import ko from '@/locales/ko'
import vi from '@/locales/vi'
import ja from '@/locales/ja'
import ru from '@/locales/ru'
import id from '@/locales/id'
import es from '@/locales/es'
import pt from '@/locales/pt'
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify';
import directSponsorFill from '@/icon/directSponsorFill.vue'
import directSponsor from '@/icon/directSponsor.vue'
import homeFill from '@/icon/homeFill.vue'
import home from '@/icon/home.vue'
import libraryFill from '@/icon/libraryFill.vue'
import library from '@/icon/library.vue'
import pairingFill from '@/icon/pairingFill.vue'
import pairing from '@/icon/pairing.vue'
import userFill from '@/icon/userFill.vue'
import user from '@/icon/user.vue'
import newsFill from '@/icon/newsFill.vue'
import news from '@/icon/news.vue'
import transactionFill from '@/icon/transactionFill.vue'
import transaction from '@/icon/transaction.vue'
import royaltyFill from '@/icon/royaltyFill.vue'
import royalty from '@/icon/royalty.vue'
import stakingFill from '@/icon/stakingFill.vue'
import stakingIcon from '@/icon/stakingIcon.vue'

Vue.use(Vuetify);
Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: 'zh', // set locale
	fallbackLocale: 'en',
	messages: {
		zh, en, ko, vi, ja, ru, id, es, pt
	}, // set locale messages
})

  
export default {
	vuetify: new Vuetify({
		i18n,
		theme: {
			dark : false,
			themes: {
				light: {
					primary: '#F28C19',
					secondary: '#FFFFFF',
					'tertiary' : '#F28C19',
					'quaternary' : "#F2B519",
					'background': '#FFFFFF',
					'opposite': '#202020',
					"minting_pack_1": "#D9EBD4",
					"minting_pack_1_gradient_1": "#32831B",
					"minting_pack_1_gradient_2": "#83C570",
					"minting_pack_2": "#FFEFDD",
					"pairing_graph_1": "#A752E9",
					"pairing_graph_2": "#E2D2EF",
					"direct_graph_1": "#4D7EFF",
					"direct_graph_2": "#E1E6F4",
					"royalty_graph_1": "#F2C349",
					"royalty_graph_2": "#ECE0C3",
					"mt_graph": "#CACACA",
					"rt_graph": "#F2B519",
					"payout_stat_color": "#F28C19"
				},
				dark: {
					primary: '#F28C19',
					secondary: '#202020',
					'tertiary' : '#F28C19',
					'quaternary' : "#F2B519",
					'background': '#181818',
					'opposite': '#FFFFFF',
				},
			},
		},
		lang: {
			t: (key, ...params) => {
				if(!key.includes("$vuetify."))
					key = "$vuetify." + key
				return i18n.t(key, params)
			},
		},
		icons: {
			values: {
				directSponsorFill: { // name of our custom icon
					component: directSponsorFill, // our custom component
				},
				homeFill: { // name of our custom icon
					component: homeFill, // our custom component
				},
				libraryFill: { // name of our custom icon
					component: libraryFill, // our custom component
				},
				pairingFill: { // name of our custom icon
					component: pairingFill, // our custom component
				},
				userFill: { // name of our custom icon
					component: userFill, // our custom component
				},
				royaltyFill: { // name of our custom icon
					component: royaltyFill, // our custom component
				},
				transactionFill: { // name of our custom icon
					component: transactionFill, // our custom component
				},
				newsFill: { // name of our custom icon
					component: newsFill, // our custom component
				},
				stakingFill: { // name of our custom icon
					component: stakingFill, // our custom component
				},

				directSponsor: { // name of our custom icon
					component: directSponsor, // our custom component
				},
				home: { // name of our custom icon
					component: home, // our custom component
				},
				library: { // name of our custom icon
					component: library, // our custom component
				},
				pairing: { // name of our custom icon
					component: pairing, // our custom component
				},
				user: { // name of our custom icon
					component: user, // our custom component
				},
				royalty: { // name of our custom icon
					component: royalty, // our custom component
				},
				transaction: { // name of our custom icon
					component: transaction, // our custom component
				},
				news: { // name of our custom icon
					component: news, // our custom component
				},
				stakingIcon: { // name of our custom icon
					component: stakingIcon, // our custom component
				},
			},
		},
	}),
	i18n
}