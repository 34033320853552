import es from 'vuetify/lib/locale/es'

const nav = {
	"Dashboard": "Tablero",
	"User Profile": "Perfil de usuario",
	"Pairing Network": "Red de emparejamiento",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "Patrocinador Directo",
	"Royalty": "Realeza",
	"Running Royalty": "Regalía en Ejecución",
	"1Move Library": "1Move biblioteca",
	"Transactions": "Actas",
	"News": "Noticias",
	"Starter Pack": "Paquete de inicio",
	"Features": "Opciones",
	"Options": "Opciones",
	"Language Switcher": "Cambiar idioma",
	"Log Out": "Cerrar sesión",
}

const token_transaction = {
	"Token Transactions": "Transacción de tokens",
	"Type": "Tipo",
	"Token Type": "Tipo de token",
	"Amount": "Importe",
	"Created at": "Creado en",
}

const starter_pack = {
	"NFT SHOE": "Zapato NFT",
	"Mint": "Como",
	"Walker": "Caminante",
	"Jogger": "Mono",
	"Runner": "Corredor",
	"Selected": "Seleccionar",
	"Total": "Total",
	"Back": "Atrás",
	"This feature is currently unavailable.": "La función para comprar el paquete de inicio no está disponible actualmente. Disculpen las molestias causadas.",
	"Click to Download Networking Info": "Haga clic para descargar la información de red",
	"Select one shoe": "Seleccione un zapato",
	"Select three shoes": "Seleccione tres zapatos",
	"OR": "O",
	"Minting Pack": "Paquete de acuñación",
}

const royalty = {
	"Royalty Payout": "Pago de regalías",
	"Last updated": "Última actualización",
	"Collect Payout": "Cobrar pago",
	"Are you sure to continue?": "¿Estás seguro de continuar?",
	"Are you sure you want to collect the payout?": "¿Estás seguro de que quieres cobrar el pago?",
	"Warning": "Advertencia"
}

const pairing_tree = {
	"Pairing Tree": "Árbol de emparejamiento",
	"Flushing Schedule": "Horario de lavado",
	"Next Flushing Date": "Próxima fecha de lavado",
	"Team": "Equipo",
	"Sneakers": "Zapatillas",
	"Starter Pack Date": "Fecha del paquete de inicio",
	"No Referral": "Sin referencia",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "Datos de referencias",
	"Direct Referral Tree": "Patrocinador Directo",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "No se han encontrado resultados.",
	"No Starter Pack": "Sin paquete de inicio",
	"Referrals By Month": "Referencias por mes",
	"Referral": "Remisión",
	"Left Tree": "Árbol izquierdo",
	"Right Tree": "Árbol derecho",
}

const date = {
	"January": "Enero",
	"February": "Febrero",
	"March": "Marzo",
	"April": "Abril",
	"May": "Mayo",
	"June": "Junio",
	"July": "Julio",
	"August": "Agosto",
	"September": "Septiembre",
	"October": "Octubre",
	"November": "Noviembre",
	"December": "Diciembre",
}

const home = {
	"Total Referral": "Referencia total",
	"Total Referral (Month)": "Referencia total (mes)",
	"Total Pairing Payout (Month)": "Pago total de emparejamiento (mes)",
	"Total Direct Referral Payout (Month)": "Pago total directo del patrocinador (mes)",
	"Direct Referral Payout": "Pago total directo del patrocinador",
	"Total Royalty Payout (Month)": "Pago total de regalías (mes)",
	"Welcome Back": "Bienvenido de nuevo",
	"Your current ranking is": "Su clasificación actual es",
	"Pairing Payout": "Pago de emparejamiento",
	"Total Accumulated": "Total Acumulado",
	"Direct Referral": "Patrocinador directo",
	"Royalty Payout": "Pago de regalías",
	"Total Members Under Pairing & Direct Referral Network": "Total de miembros bajo emparejamiento y red de patrocinadores directos",
	"Total Starter Pack Under Pairing & Direct Referral Network": "Paquete de inicio total bajo emparejamiento y red de patrocinadores directos",
	"Total Registered Account Under Pairing & Direct Referral Network": "Total de cuentas registradas en emparejamiento y red de patrocinadores directos",
	"Token": "Simbólico",
	"Items Owned": "Artículos de propiedad",
	"Artifacts": "Artefactos",
	"Shoeboxes": "Cajas de zapatos",
	"Referrals By Country": "Referencias por país",
	"News Feed": "Noticias",
}

const user = {
	"Email": "Correo electrónico",
	"Name": "Name",
	"Ranking": "Clasificación",
	"Left Side Pairing Amount": "Cantidad de emparejamiento del lado izquierdo",
	"Right Side Pairing Amount": "Cantidad de emparejamiento del lado derecho",
	"Referred By": "Referido por",
	"Joined at": "Se unió en",
	"Status": "Estado",
	"Active": "Activo",
	"Deactivated": "Desactivó",
	"Uncalculated": "sin calcular",
	"Pending": "pendiente",
	"Rank": "Rango",
	"Registered On": "Registrado en",
	"Activated On": "activado en",
	"Total Distance": "Distancia total",
	"Total Income": "Ingresos totales",
	"Total Runs": "Ejecuciones totales",
	"times": "veces",
	"Total Run Time": "Tiempo total de ejecución",
	"hours": "horas",
}

const misc = {
	"Filters": "Filtros",
	"Type": "Tipo",
	"Apply": "Aplicar",
}

const user_token = {
	"Next Flushing Date": "Próxima fecha de lavado",
	"Pairing Tree": "Árbol de emparejamiento",
	"Flushing Schedule": "Horario de lavado",
	"No Referral": "Sin referencia",
	"Receive pairing payout": "Recibir pago de emparejamiento",
	"Payout Details": "Detalles del pago",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "Zapatillas de deporte izquierdas",
	"Right Sneakers": "Zapatillas de deporte correctas",
	"Total Sneakers": "Total Zapatillas",
	"Payout in USDT": "Pago en USDT",
	"Payout in 1MT": "Pago en 1MT",
	"USDT to 1RT Conversion Rate": "Tasa de conversión de USDT a 1RT",
	"1MT to 1RT Conversion Rate": "Tasa de conversión de 1MT a 1RT",
	"1RT Received": "1RT recibido",
	"Pairing Carry Forward Details": "Emparejamiento De los detalles de Carry Forward",
	"Pairing Flushing Details": "Emparejamiento de detalles de lavado",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "Staking",
	"Staking Info": "Información de Staking",
	"Staking Portfolio": "Portafolio de Staking",
	"Staking History": "Historial de Staking",
	"Next Staking": "Siguiente Staking",
	"Buy Now": "Comprar Ahora",
	"Buy Lot": "Comprar Lote",
	"Buy Lot Amount": "Cantidad Comprada del Lote",
	"Total Amount": "Monto Total",
	"Buy": "Comprar",
	"Lots": "Lotes",
	"Cancel": "Cancelar",
	"Staking Availability Table": "Tabla de Disponibilidad de Staking",
	"Total 1MT Pool of Funds": "Total 1MT en el Fondo de Pool",
	"Coming Soon": "Próximamente",
	"Lot Amount": "Monto del Lote",
	"Amount Purchased": "Monto Comprado",
	"Maturity Date": "Fecha de Vencimiento",
	"1RT Earning": "Ganancia 1RT",
	"BNB Earning": "Ganancia BNB",
	"Total Staking": "Total de Staking",
	"Staking Live": "Staking en vivo",
	"Ends On": "Finaliza el",
	"OTP Code": "Código OTP",
	"Request OTP": "Solicitar OTP",
	"OTP code sent.": "Código OTP enviado.",
	"Sold Out": "Agotado",
	"Sell": "Vender",
	"Sell Lot": "Vender Lote",
	"Available": "Disponible",
	"Penalty": "Penalización",
	"Final Amount": "Monto Final",
	"1RT Payout": "Pago de 1RT",
	"Unsubscribe Lot Amount": "Cantidad de Lotes para Cancelar Suscripción",
	"Unsubscribe Lot": "Cancelar Suscripción del Lote",
	"Unsubscribe": "Cancelar Suscripción",
	"Month": "Mes",
	"Year": "Año",
	"Total Point": "Punto Total",
	"Point": "Punto",
	"BNB Point Ratio": "Proporción de Puntos BNB",
	"Staking Amount": "Monto de Stake",
	"BNB Received": "BNB Recibido",
}

export default {
	$vuetify: {
		...es
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}