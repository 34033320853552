<template>
	<svg width="30" height="30" 
		viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M22.5 27.5758L21.8376 26.9134L21.8365 26.9124L16.8371 21.9129C16.471 21.5468 16.471 20.9532 16.8371 20.5871C17.2032 20.221 17.7968 20.221 18.1629 20.5871L21.5625 23.9867L21.5625 18.7353C21.5625 17.7735 21.5565 17.4453 21.4848 17.1467C21.4159 16.8598 21.3022 16.5854 21.148 16.3338C20.9876 16.072 20.7598 15.8357 20.0797 15.1556L16.0945 11.1704L16.0189 11.0949C15.4428 10.5191 15.0441 10.1206 14.7533 9.64603C14.4962 9.22663 14.3068 8.76938 14.192 8.29108C14.0621 7.74987 14.0622 7.18611 14.0625 6.37167L14.0625 6.37164L14.0625 6.26481L14.0625 3.75C14.0625 3.23223 14.4822 2.8125 15 2.8125C15.5178 2.8125 15.9375 3.23223 15.9375 3.75L15.9375 6.26481C15.9375 7.22666 15.9435 7.55478 16.0152 7.85337C16.0841 8.14035 16.1977 8.4147 16.352 8.66634C16.5124 8.92816 16.7402 9.16444 17.4203 9.84457L21.4055 13.8297L21.4811 13.9052L21.4811 13.9052C22.0572 14.481 22.4559 14.8795 22.7467 15.3541C23.0038 15.7735 23.1931 16.2307 23.308 16.709C23.4379 17.2503 23.4378 17.814 23.4375 18.6285L23.4375 18.6285L23.4375 18.7353L23.4375 23.9867L26.8371 20.5871C27.2032 20.221 27.7968 20.221 28.1629 20.5871C28.529 20.9532 28.529 21.5468 28.1629 21.9129L23.1629 26.9129L22.5 27.5758ZM7.5 27.5758L8.16242 26.9134L8.16347 26.9123L13.1629 21.9129C13.529 21.5468 13.529 20.9532 13.1629 20.5871C12.7968 20.221 12.2032 20.221 11.8371 20.5871L8.4375 23.9867L8.4375 18.7353C8.4375 17.7734 8.44352 17.4453 8.51521 17.1467C8.58411 16.8597 8.69774 16.5854 8.85195 16.3338C9.01239 16.0719 9.24015 15.8357 9.92027 15.1555L11.9129 13.1629L12.5758 12.5L11.25 11.1742L10.5871 11.8371L8.59445 13.8297L8.51889 13.9052C7.94282 14.481 7.54407 14.8795 7.25325 15.3541C6.99624 15.7735 6.80684 16.2307 6.69202 16.709C6.56208 17.2502 6.56224 17.814 6.56248 18.6284L6.56248 18.6284L6.5625 18.7353L6.5625 23.9867L3.16291 20.5871C2.79679 20.221 2.2032 20.221 1.83708 20.5871C1.47097 20.9532 1.47097 21.5468 1.83709 21.9129L6.83709 26.9129L7.5 27.5758Z" fill="#B8B8B8"/>
	</svg>
</template>

<script>
export default {
	name: "pairing-default"
};
</script>

<style>

</style>