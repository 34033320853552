<template>
	<svg width="30" height="30" 
		viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M19.0782 2.25384C19.0782 0.447409 17.0568 -0.622518 15.563 0.393253L2.07903 9.56235C0.778481 10.4467 0 11.9175 0 13.4903V16.2872C0 17.8599 0.778481 19.3307 2.07903 20.2151L15.563 29.3842C17.0568 30.3999 19.0782 29.33 19.0782 27.5236V2.25384ZM16.4065 1.63364C16.9044 1.29505 17.5782 1.6517 17.5782 2.25384V27.5236C17.5782 28.1257 16.9044 28.4824 16.4065 28.1438L2.92249 18.9747C2.03264 18.3696 1.5 17.3632 1.5 16.2872V13.4903C1.5 12.4142 2.03264 11.4078 2.92249 10.8027L16.4065 1.63364ZM23.2354 7.60208C22.5497 7.30225 22.0452 7.20115 21.844 7.20115V5.70115C22.3459 5.70115 23.0719 5.89344 23.8364 6.22772C24.6233 6.57182 25.5234 7.09771 26.3765 7.83263C28.0929 9.31134 29.6253 11.6468 29.6253 15.0075C29.6253 18.3716 28.0896 20.6523 26.3639 22.0742C25.5076 22.7797 24.6053 23.2745 23.8183 23.5944C23.0502 23.9067 22.3325 24.0762 21.844 24.0762V22.5762C22.0586 22.5762 22.5714 22.4821 23.2534 22.2048C23.9167 21.9352 24.6843 21.5145 25.41 20.9165C26.8484 19.7314 28.1253 17.8528 28.1253 15.0075C28.1253 12.159 26.8451 10.2162 25.3974 8.96907C24.6685 8.34106 23.8987 7.8921 23.2354 7.60208ZM25.3596 14.8887C25.3596 11.3731 21.844 10.6699 21.844 10.6699V19.1075C21.844 19.1075 25.3596 18.4044 25.3596 14.8887Z" fill="#B8B8B8"/>
	</svg>
</template>

<script>
export default {
	name: "news-default"
};
</script>

<style>

</style>