import axios from 'axios';

let prefix = "/payout"

const PayoutApi = {
	getRoyaltyPayoutInfo(payload){
		return axios.get( prefix + "/royalty/get", {params: payload})
	},
	collectRoyaltyPayout(payload){
		return axios.post( prefix + "/royalty/collect", payload)
	},
}

export default PayoutApi;