import axios from 'axios';

let prefix = "/direct-sponsor"

const DirectSponsorApi = {
	getDirectSponsorTree(payload){
		return axios.get( prefix + "/list-all", {params: payload})
	},
	getReferralData(payload){
		return axios.get( prefix + "/referral-data", {params: payload})
	},
	getDirectSponsorList(payload){
		return axios.get( prefix + "/tree", {params: payload})
	},
}

export default DirectSponsorApi;