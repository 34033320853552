import ru from 'vuetify/lib/locale/ru'

const nav = {
	"Dashboard": "Приборная панель",
	"User Profile": "Профиль пользователя",
	"Pairing Network": "Сопряжение сети",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "Сопряжение сети",
	"Royalty": "Королевская власть",
	"Running Royalty": "Раннинг-роялти",
	"1Move Library": "1Move Библиотека",
	"Transactions": "Транзакции",
	"News": "Новости",
	"Starter Pack": "Стартовый пакет",
	"Features": "Функция",
	"Options": "Параметры",
	"Language Switcher": "Переключатель языков",
	"Log Out": "Выходить",
}

const token_transaction = {
	"Token Transactions": "Транзакция токена",
	"Type": "Тип",
	"Token Type": "Тип токена",
	"Amount": "Количество",
	"Created at": "Создано в",
}

const starter_pack = {
	"NFT SHOE": "Обувь NFT",
	"Mint": "Как",
	"Walker": "Ходок",
	"Jogger": "Бегун",
	"Runner": "Бегун",
	"Selected": "Выбрать",
	"Total": "Итог",
	"Back": "Назад",
	"This feature is currently unavailable.": "Эта функция в данный момент недоступна.",
	"Click to Download Networking Info": "Нажмите, чтобы загрузить информацию о сети",
	"Select one shoe": "Выберите один ботинок",
	"Select three shoes": "Выберите три обуви",
	"OR": "ИЛИ",
	"Minting Pack": "Пакет чеканки",
}

const royalty = {
	"Royalty Payout": "Выплата роялти",
	"Last updated": "Последнее обновление",
	"Collect Payout": "Собрать выплату",
	"Are you sure to continue?": "Вы уверены, что продолжите?",
	"Are you sure you want to collect the payout?": "Вы уверены, что хотите получить выплату?",
	"Warning": "Предупреждение"
}

const pairing_tree = {
	"Pairing Tree": "Дерево сопряжения",
	"Flushing Schedule": "График промывки",
	"Next Flushing Date": "Следующая дата промывки",
	"Team": "Команда",
	"Sneakers": "Кроссовки",
	"Starter Pack Date": "Дата стартового пакета",
	"No Referral": "Нет рефералов",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "Данные рефералов",
	"Direct Referral Tree": "Прямые спонсоры",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "Результатов не найдено.",
	"No Starter Pack": "Нет стартового пакета",
	"Referrals By Month": "Рефералы по месяцам",
	"Referral": "Направления",
	"Left Tree": "Левое дерево",
	"Right Tree": "Правое дерево",
}

const date = {
	"January": "Январь",
	"February": "Февраль",
	"March": "Март",
	"April": "Апрель",
	"May": "Май",
	"June": "Июнь",
	"July": "Июль",
	"August": "Август",
	"September": "Сентябрь",
	"October": "Октябрь",
	"November": "Ноябрь",
	"December": "Декабрь",
}

const home = {
	"Total Referral": "Всего рефералов",
	"Total Referral (Month)": "Всего рефералов (месяц)",
	"Total Pairing Payout (Month)": "Общая выплата по паре (месяц)",
	"Total Direct Referral Payout (Month)": "Общая выплата прямого спонсора (месяц)",
	"Direct Referral Payout": "Общая выплата прямого спонсора",
	"Total Royalty Payout (Month)": "Общая выплата роялти (месяц)",
	"Welcome Back": "Добро пожаловать",
	"Your current ranking is": "Ваш текущий рейтинг",
	"Pairing Payout": "Выплата за сопряжение",
	"Total Accumulated": "Всего накоплено",
	"Direct Referral": "Прямой спонсор",
	"Royalty Payout": "Выплата роялти",
	"Total Members Under Pairing & Direct Referral Network": "Всего участников в собственной сети",
	"Total Starter Pack Under Pairing & Direct Referral Network": "Общий стартовый пакет в собственной сети",
	"Total Registered Account Under Pairing & Direct Referral Network": "Всего зарегистрированных аккаунтов в собственной сети",
	"Token": "Токен",
	"Items Owned": "Предметы в собственности",
	"Artifacts": "Артефакты",
	"Shoeboxes": "Обувные коробки",
	"Referrals By Country": "Рекомендации по странам",
	"News Feed": "Новостная лента",
}

const user = {
	"Email": "Отправить по электронной почте",
	"Name": "Name",
	"Ranking": "Ранжирование",
	"Left Side Pairing Amount": "Сумма сопряжения с левой стороной",
	"Right Side Pairing Amount": "Сумма сопряжения с правой стороной",
	"Referred By": "Рекомендовано",
	"Joined at": "Присоединились к",
	"Status": "Статус",
	"Active": "Активный",
	"Deactivated": "Деактивировано",
	"Uncalculated": "несчитанная",
	"Pending": "в ожидании",
	"Rank": "Классифицировать",
	"Registered On": "Зарегистрирован",
	"Activated On": "Активировано на",
	"Total Distance": "Общее расстояние",
	"Total Income": "Общая прибыль",
	"Total Runs": "Всего запусков",
	"times": "раз",
	"Total Run Time": "Общее время выполнения",
	"hours": "часы",
}

const misc = {
	"Filters": "Фильтры",
	"Type": "Type",
	"Apply": "Применять",
}

const user_token = {
	"Next Flushing Date": "Следующая дата промывки",
	"Pairing Tree": "Дерево сопряжения",
	"Flushing Schedule": "График промывки",
	"No Referral": "Нет рефералов",
	"Receive pairing payout": "Получите парную выплату",
	"Payout Details": "Сведения о выплатах",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "Левые кроссовки",
	"Right Sneakers": "Правые кроссовки",
	"Total Sneakers": "Всего кроссовок",
	"Payout in USDT": "Выплата в USDT",
	"Payout in 1MT": "Выплата в 1MT",
	"USDT to 1RT Conversion Rate": "Коэффициент конвертации USDT в 1RT",
	"1MT to 1RT Conversion Rate": "Коэффициент конвертации 1MT в 1RT",
	"1RT Received": "1RT Получено",
	"Pairing Carry Forward Details": "Сопряжение сведений о переносе вперед",
	"Pairing Flushing Details": "Сопряжение деталей промывки",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "Стейкинг",
	"Staking Info": "Информация о стейкинге",
	"Staking Portfolio": "Портфель стейкинга",
	"Staking History": "История стейкинга",
	"Next Staking": "Следующий стейкинг",
	"Buy Now": "Купить сейчас",
	"Buy Lot": "Купить лот",
	"Buy Lot Amount": "Сумма покупки лота",
	"Total Amount": "Общая сумма",
	"Buy": "Купить",
	"Lots": "Лоты",
	"Cancel": "Отменить",
	"Staking Availability Table": "Таблица доступности стейкинга",
	"Total 1MT Pool of Funds": "Всего 1MT пул средств",
	"Coming Soon": "Скоро",
	"Lot Amount": "Сумма лота",
	"Amount Purchased": "Купленная сумма",
	"Maturity Date": "Дата погашения",
	"1RT Earning": "Заработок 1RT",
	"BNB Earning": "Заработок BNB",
	"Total Staking": "Всего стейкинга",
	"Staking Live": "Стейкинг в режиме реального времени",
	"Ends On": "Окончание",
	"OTP Code": "Код OTP",
	"Request OTP": "Запросить OTP",
	"OTP code sent.": "Код OTP отправлен.",
	"Sold Out": "Продано",
	"Sell": "Продать",
	"Sell Lot": "Продать лот",
	"Available": "Доступно",
	"Penalty": "Штраф",
	"Final Amount": "Итоговая сумма",
	"1RT Payout": "Выплата 1RT",
	"Unsubscribe Lot Amount": "Количество лотов для отмены подписки",
	"Unsubscribe Lot": "Отменить подписку на лот",
	"Unsubscribe": "Отменить подписку",
	"Month": "Месяц",
	"Year": "Год",
	"Total Point": "Общее Количество Баллов",
	"Point": "Балл",
	"BNB Point Ratio": "Соотношение Баллов BNB",
	"Staking Amount": "Сумма Стейкинга",
	"BNB Received": "Получено BNB",
}

export default {
	$vuetify: {
		...ru
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}