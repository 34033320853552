import ko from 'vuetify/lib/locale/ko'

const nav = {
	"Dashboard": "계기반",
	"User Profile": "사용자 프로필",
	"Pairing Network": "페어링 네트워크",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "직접 스폰서",
	"Royalty": "왕족",
	"Running Royalty": "운영 로열티",
	"1Move Library": "1Move 도서관",
	"Transactions": "업무",
	"News": "소식",
	"Starter Pack": "스타터 팩",
	"Features": "기능",
	"Options": "옵션",
	"Language Switcher": "언어 전환기",
	"Log Out": "로그아웃",
}

const token_transaction = {
	"Token Transactions": "토큰 트랜잭션",
	"Type": "형",
	"Token Type": "토큰 유형",
	"Amount": "분량",
	"Created at": "만든 위치",
}

const starter_pack = {
	"NFT SHOE": "NFT 슈즈",
	"Mint": "만큼",
	"Walker": "보행자",
	"Jogger": "조깅",
	"Runner": "러너",
	"Selected": "선택",
	"Total": "합계",
	"Back": "뒤로",
	"This feature is currently unavailable.": "이 기능은 현재 사용할 수 없습니다",
	"Click to Download Networking Info": "네트워킹 정보를 다운로드하려면 클릭하십시오.",
	"Select one shoe": "신발 하나 선택",
	"Select three shoes": "세 가지 신발 선택",
	"OR": "또는",
	"Minting Pack": "민트 팩",
}

const royalty = {
	"Royalty Payout": "로열티 지불금",
	"Last updated": "마지막 업데이트",
	"Collect Payout": "지불금 수집",
	"Are you sure to continue?": "계속하시겠습니까?",
	"Are you sure you want to collect the payout?": "판매 대금을 수령하시겠습니까?",
	"Warning": "경고"
}

const pairing_tree = {
	"Pairing Tree": "페어링 트리",
	"Flushing Schedule": "플러싱 일정",
	"Next Flushing Date": "다음 플러싱 날짜",
	"Team": "팀",
	"Sneakers": "운동화",
	"Starter Pack Date": "스타터 팩 날짜",
	"No Referral": "추천 없음",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "추천 데이터",
	"Direct Referral Tree": "직접 스폰서",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "검색된 결과가 없습니다",
	"No Starter Pack": "스타터 팩 없음",
	"Referrals By Month": "월별 추천",
	"Referral": "추천",
	"Left Tree": "왼쪽 트리",
	"Right Tree": "오른쪽 트리",
}

const date = {
	"January": "1월",
	"February": "2월",
	"March": "3월",
	"April": "4월",
	"May": "5월",
	"June": "6월",
	"July": "7월",
	"August": "8월",
	"September": "9월",
	"October": "10월",
	"November": "11월",
	"December": "12월",
}

const home = {
	"Total Referral": "총 추천",
	"Total Referral (Month)": "총 추천 (월)",
	"Total Pairing Payout (Month)": "총 페어링 지불금(월)",
	"Total Direct Referral Payout (Month)": "총 직접 스폰서 지불금 (월)",
	"Direct Referral Payout": "총 직접 스폰서 지불금",
	"Total Royalty Payout (Month)": "총 로열티 지불금 (월)",
	"Welcome Back": "다시 오신 것을 환영합니다",
	"Your current ranking is": "현재 순위는",
	"Pairing Payout": "페어링 지불금",
	"Total Accumulated": "총 누적",
	"Direct Referral": "직접 후원자",
	"Royalty Payout": "로열티 지급",
	"Total Members Under Pairing & Direct Referral Network": "페어링 및 직접 스폰서 네트워크의 총 구성원",
	"Total Starter Pack Under Pairing & Direct Referral Network": "페어링 및 다이렉트 스폰서 네트워크에서 전체 스타터 팩",
	"Total Registered Account Under Pairing & Direct Referral Network": "페어링 및 다이렉트 스폰서 네트워크에 등록된 총 계정",
	"Token": "토큰",
	"Items Owned": "소유 아이템",
	"Artifacts": "유물",
	"Shoeboxes": "신발장",
	"Referrals By Country": "국가별 추천",
	"News Feed": "뉴스 피드",
}

const user = {
	"Email": "이메일",
	"Name": "Name",
	"Ranking": "순위",
	"Left Side Pairing Amount": "왼쪽 페어링 양",
	"Right Side Pairing Amount": "오른쪽 페어링 양",
	"Referred By": "추천인",
	"Joined at": "에 가입",
	"Status": "상태",
	"Active": "활동적인",
	"Deactivated": "비활성화됨",
	"Uncalculated": "계산되지 않음",
	"Pending": "보류 중",
	"Rank": "계급",
	"Registered On": "등록 날짜",
	"Activated On": "활성화됨",
	"Total Distance": "총 거리",
	"Total Income": "총 수입",
	"Total Runs": "총 실행",
	"times": "타임스",
	"Total Run Time": "총 실행 시간",
	"hours": "시간",
}

const misc = {
	"Filters": "필터",
	"Type": "형",
	"Apply": "적용하다",
}

const user_token = {
	"Next Flushing Date": "다음 플러싱 날짜",
	"Pairing Tree": "페어링 트리",
	"Flushing Schedule": "플러싱 일정",
	"No Referral": "추천 없음",
	"Receive pairing payout": "페어링 지불금 받기",
	"Payout Details": "지급 세부 정보",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "레프트 스니커즈",
	"Right Sneakers": "오른쪽 스니커즈",
	"Total Sneakers": "토탈 스니커즈",
	"Payout in USDT": "USDT로 지급",
	"Payout in 1MT": "1MT로 지급",
	"USDT to 1RT Conversion Rate": "USDT에서 1RT 전환율로",
	"1MT to 1RT Conversion Rate": "1MT에서 1RT 전환율로",
	"1RT Received": "1RT 수신",
	"Pairing Carry Forward Details": "페어링 앞으로 전달 세부 정보",
	"Pairing Flushing Details": "페어링 플러시 세부 정보",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "스테이킹",
	"Staking Info": "스테이킹 정보",
	"Staking Portfolio": "스테이킹 포트폴리오",
	"Staking History": "스테이킹 내역",
	"Next Staking": "다음 스테이킹",
	"Buy Now": "지금 구매",
	"Buy Lot": "로트 구매",
	"Buy Lot Amount": "로트 금액",
	"Total Amount": "총 금액",
	"Buy": "구매",
	"Lots": "로트",
	"Cancel": "취소",
	"Staking Availability Table": "스테이킹 가능성 표",
	"Total 1MT Pool of Funds": "총 1MT 풀 자금",
	"Coming Soon": "곧 출시",
	"Lot Amount": "로트 금액",
	"Amount Purchased": "구매 금액",
	"Maturity Date": "만기일",
	"1RT Earning": "1RT 수익",
	"BNB Earning": "BNB 수익",
	"Total Staking": "총 스테이킹",
	"Staking Live": "라이브 스테이킹",
	"Ends On": "종료일",
	"OTP Code": "OTP 코드",
	"Request OTP": "OTP 요청",
	"OTP code sent.": "OTP 코드가 전송되었습니다.",
	"Sold Out": "매진",
	"Sell": "판매",
	"Sell Lot": "로트 판매",
	"Available": "사용 가능",
	"Penalty": "벌금",
	"Final Amount": "최종 금액",
	"1RT Payout": "1RT 지급",
	"Unsubscribe Lot Amount": "구독 해지할 로트 수량",
	"Unsubscribe Lot": "구독 해지할 로트",
	"Unsubscribe": "구독 해지",
	"Month": "월",
	"Year": "년",
	"Total Point": "총 포인트",
	"Point": "포인트",
	"BNB Point Ratio": "BNB 포인트 비율",
	"Staking Amount": "스테이킹 금액",
	"BNB Received": "받은 BNB",
}

export default {
	$vuetify: {
		...ko
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}