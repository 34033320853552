<template>
	<svg width="29" height="29" 
		viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M1.75076 18.8436L15.2347 28.0127C16.2306 28.6899 17.5782 27.9766 17.5782 26.7723V1.50256C17.5782 0.298273 16.2306 -0.415013 15.2347 0.262169L1.75076 9.43127C0.655563 10.176 0 11.4146 0 12.739V15.5359C0 16.8603 0.655563 18.0988 1.75076 18.8436ZM22.4854 6.8508C21.7997 6.55097 21.2952 6.44988 21.094 6.44988V4.94988C21.5959 4.94988 22.3219 5.14216 23.0864 5.47645C23.8733 5.82054 24.7734 6.34643 25.6265 7.08135C27.3429 8.56006 28.8753 10.8955 28.8753 14.2562C28.8753 17.6203 27.3396 19.901 25.6139 21.3229C24.7576 22.0284 23.8553 22.5232 23.0683 22.8431C22.3002 23.1554 21.5825 23.3249 21.094 23.3249V21.8249C21.3086 21.8249 21.8214 21.7308 22.5034 21.4535C23.1667 21.1839 23.9343 20.7633 24.66 20.1653C26.0984 18.9801 27.3753 17.1015 27.3753 14.2562C27.3753 11.4077 26.0951 9.46494 24.6474 8.21779C23.9185 7.58978 23.1487 7.14082 22.4854 6.8508ZM24.6096 14.1374C24.6096 10.6218 21.094 9.91866 21.094 9.91866V18.3562C21.094 18.3562 24.6096 17.6531 24.6096 14.1374Z" fill="black"/>
	</svg>
</template>

<script>
export default {
	name: "newsFill"
};
</script>

<style>

</style>