import axios from 'axios';

let prefix = "/user-token"

const UserTokenApi = {
	
	getUserTokenList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getUserTokenRefs(payload){
		return axios.get( prefix + "/refs", {params: payload})
	},
	createUserToken(payload){
		return axios.post( prefix + "/create", payload)
	},
}

export default UserTokenApi;