import vi from 'vuetify/lib/locale/vi'

const nav = {
	"Dashboard": "bảng điều khiển",
	"User Profile": "Hồ sơ người dùng",
	"Pairing Network": "Kết nối mạng",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "Nhà tài trợ trực tiếp",
	"Royalty": "Royalty",
	"Running Royalty": "Phí tài trợ chạy",
	"1Move Library": "1Move Thư viện",
	"Transactions": "giao dịch",
	"News": "Tin tức",
	"Starter Pack": "Gói khởi động",
	"Features": "Hàm số",
	"Options": "Tùy chọn",
	"Language Switcher": "Trình chuyển đổi ngôn ngữ",
	"Log Out": "Đăng xuất",
}

const token_transaction = {
	"Token Transactions": "Giao dịch token",
	"Type": "Kiểu",
	"Token Type": "Loại token",
	"Amount": "Lượng",
	"Created at": "Được tạo tại",
}

const starter_pack = {
	"NFT SHOE": "Giày NFT",
	"Mint": "Như",
	"Walker": "Walker",
	"Jogger": "Người chạy bộ",
	"Runner": "Runner",
	"Selected": "Chọn",
	"Total": "Tất cả",
	"Back": "Lưng",
	"This feature is currently unavailable.": "Tính năng này hiện không khả dụng.",
	"Click to Download Networking Info": "Nhấp để tải xuống thông tin mạng",
	"Select one shoe": "Chọn một chiếc giày",
	"Select three shoes": "Chọn ba chiếc giày",
	"OR": "HOẶC",
	"Minting Pack": "gói bạc hà",
}

const royalty = {
	"Royalty Payout": "Thanh toán tiền bản quyền",
	"Last updated": "Cập nhật lần cuối",
	"Collect Payout": "Thu thập thanh toán",
	"Are you sure to continue?": "Bạn có chắc chắn để tiếp tục?",
	"Are you sure you want to collect the payout?": "Bạn có chắc chắn muốn nhận khoản thanh toán không?",
	"Warning": "Cảnh báo"
}

const pairing_tree = {
	"Pairing Tree": "Cây ghép nối",
	"Flushing Schedule": "Lịch trình flushing",
	"Next Flushing Date": "Ngày flushing tiếp theo",
	"Team": "Đội",
	"Sneakers": "Sneakers",
	"Starter Pack Date": "Ngày gói dành cho người mới bắt đầu",
	"No Referral": "Không có giới thiệu",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "Dữ liệu giới thiệu",
	"Direct Referral Tree": "Cây Nhà tài trợ trực tiếp",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "Không có kết quả nào.",
	"No Starter Pack": "Không có gói khởi động",
	"Referrals By Month": "Giới thiệu theo tháng",
	"Referral": "Giới thiệu",
	"Left Tree": "Cây trái",
	"Right Tree": "cây bên phải",
}

const date = {
	"January": "Tháng 1",
	"February": "Tháng hai",
	"March": "Tháng 3",
	"April": "Tháng 4",
	"May": "Tháng 5",
	"June": "Tháng 6",
	"July": "Tháng 7",
	"August": "Tháng 8",
	"September": "Tháng 9",
	"October": "Tháng 10",
	"November": "Tháng 11",
	"December": "Tháng 12",
}

const home = {
	"Total Referral": "Tổng số lượt giới thiệu",
	"Total Referral (Month)": "Tổng số lượt giới thiệu (tháng)",
	"Total Pairing Payout (Month)": "Tổng số tiền thanh toán khi ghép nối (tháng)",
	"Total Direct Referral Payout (Month)": "Tổng số tiền thanh toán của nhà tài trợ trực tiếp (tháng)",
	"Direct Referral Payout": "Tổng số tiền thanh toán của nhà tài trợ trực tiếp",
	"Total Royalty Payout (Month)": "Tổng số tiền bản quyền thanh toán (tháng)",
	"Welcome Back": "Chào mừng trở lại",
	"Your current ranking is": "Xếp hạng hiện tại của bạn là",
	"Pairing Payout": "Xuất chi theo cặp",
	"Total Accumulated": "Tổng cộng lũy ​​kế",
	"Direct Referral": "Nhà tài trợ trực tiếp",
	"Royalty Payout": "Xuất chi tiền bản quyền",
	"Total Members Under Pairing & Direct Referral Network": "Tổng số thành viên đang ghép nối & Mạng lưới tài trợ trực tiếp",
	"Total Starter Pack Under Pairing & Direct Referral Network": "Gói dành cho Người mới bắt đầu trong Mạng lưới Ghép đôi & Nhà tài trợ Trực tiếp",
	"Total Registered Account Under Pairing & Direct Referral Network": "Tổng số tài khoản đã đăng ký theo mạng ghép nối & tài trợ trực tiếp",
	"Token": "Mã thông báo",
	"Items Owned": "Các mặt hàng sở hữu",
	"Artifacts": "Hiện vật",
	"Shoeboxes": "Những hộp giày",
	"Referrals By Country": "Giới thiệu theo quốc gia",
	"News Feed": "Nguồn cấp tin tức",
}

const user = {
	"Email": "Email",
	"Name": "Name",
	"Ranking": "Xếp hạng",
	"Left Side Pairing Amount": "Số tiền ghép nối bên trái",
	"Right Side Pairing Amount": "Số tiền ghép nối bên phải",
	"Referred By": "Được giới thiệu bởi",
	"Joined at": "Tham gia tại",
	"Status": "Tình trạng",
	"Active": "Hoạt động",
	"Deactivated": "Đã hủy kích hoạt",
	"Uncalculated": "Chưa tính toán",
	"Pending": "Đang chờ xử lý",
	"Rank": "Thứ hạng",
	"Registered On": "Đắng ký trên",
	"Activated On": "kích hoạt trên",
	"Total Distance": "Tổng khoảng cách",
	"Total Income": "Tổng thu nhập",
	"Total Runs": "Tổng số lần chạy",
	"times": "lần",
	"Total Run Time": "Tổng thời gian chạy",
	"hours": "giờ",
}

const misc = {
	"Filters": "Bộ lọc",
	"Type": "Kiểu",
	"Apply": "Áp dụng",
}

const user_token = {
	"Next Flushing Date": "Ngày flushing tiếp theo",
	"Pairing Tree": "Cây ghép nối",
	"Flushing Schedule": "Lịch trình flushing",
	"No Referral": "Không có giới thiệu",
	"Receive pairing payout": "Nhận thanh toán ghép nối",
	"Payout Details": "Chi tiết thanh toán",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "Giày thể thao bên trái",
	"Right Sneakers": "Giày thể thao bên phải",
	"Total Sneakers": "Tổng số giày thể thao",
	"Payout in USDT": "Thanh toán bằng USDT",
	"Payout in 1MT": "Thanh toán bằng 1MT",
	"USDT to 1RT Conversion Rate": "Tỷ lệ chuyển đổi USDT sang 1RT",
	"1MT to 1RT Conversion Rate": "Tỷ lệ chuyển đổi 1MT sang 1RT",
	"1RT Received": "Đã nhận được 1RT 已接收",
	"Pairing Carry Forward Details": "Ghép nối Chi tiết Carry Forward",
	"Pairing Flushing Details": "Chi tiết ghép nối Flushing",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "Staking",
	"Staking Info": "Thông tin Staking",
	"Staking Portfolio": "Hồ sơ Staking",
	"Staking History": "Lịch sử Staking",
	"Next Staking": "Staking tiếp theo",
	"Buy Now": "Mua Ngay",
	"Buy Lot": "Mua Lô",
	"Buy Lot Amount": "Số Lượng Mua Lô",
	"Total Amount": "Tổng Số Tiền",
	"Buy": "Mua",
	"Lots": "Lô",
	"Cancel": "Hủy",
	"Staking Availability Table": "Bảng Khả dụng Staking",
	"Total 1MT Pool of Funds": "Tổng 1MT Quỹ Tiền",
	"Coming Soon": "Sắp Ra Mắt",
	"Lot Amount": "Số Lượng Lô",
	"Amount Purchased": "Số Tiền Được Mua",
	"Maturity Date": "Ngày Đáo Hạn",
	"1RT Earning": "Thu Nhập 1RT",
	"BNB Earning": "Thu Nhập BNB",
	"Total Staking": "Tổng Staking",
	"Staking Live": "Staking Trực tiếp",
	"Ends On": "Kết thúc vào",
	"OTP Code": "Mã OTP",
	"Request OTP": "Yêu cầu OTP",
	"OTP code sent.": "Mã OTP đã được gửi.",
	"Sold Out": "Hết hàng",
	"Sell": "Bán",
	"Sell Lot": "Bán Lô",
	"Available": "Có sẵn",
	"Penalty": "Phạt",
	"Final Amount": "Số tiền cuối cùng",
	"1RT Payout": "Thanh toán 1RT",
	"Unsubscribe Lot Amount": "Số lượng Lô Hủy đăng ký",
	"Unsubscribe Lot": "Lô Hủy đăng ký",
	"Unsubscribe": "Hủy đăng ký",
	"Month": "Tháng",
	"Year": "Năm",
	"Total Point": "Tổng Điểm",
	"Point": "Điểm",
	"BNB Point Ratio": "Tỷ lệ Điểm BNB",
	"Staking Amount": "Số Tiền Stake",
	"BNB Received": "Số BNB Nhận Được",
}

export default {
	$vuetify: {
		...vi
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}