<template>
	<svg width="28" height="26" 
		viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M21.5 25.5758L20.8376 24.9134L20.8365 24.9124L15.8371 19.9129C15.471 19.5468 15.471 18.9532 15.8371 18.5871C16.2032 18.221 16.7968 18.221 17.1629 18.5871L20.5625 21.9867L20.5625 16.7353C20.5625 15.7735 20.5565 15.4453 20.4848 15.1467C20.4159 14.8598 20.3022 14.5854 20.148 14.3338C19.9876 14.072 19.7598 13.8357 19.0797 13.1556L15.0945 9.1704L15.0189 9.09487C14.4428 8.51914 14.0441 8.12061 13.7533 7.64603C13.4962 7.22663 13.3068 6.76938 13.192 6.29108C13.0621 5.74987 13.0622 5.18611 13.0625 4.37167L13.0625 4.37164L13.0625 4.26481L13.0625 1.75C13.0625 1.23223 13.4822 0.812499 14 0.812499C14.5178 0.812499 14.9375 1.23223 14.9375 1.75L14.9375 4.26481C14.9375 5.22666 14.9435 5.55478 15.0152 5.85337C15.0841 6.14035 15.1977 6.4147 15.352 6.66634C15.5124 6.92816 15.7402 7.16444 16.4203 7.84457L20.4055 11.8297L20.4811 11.9052L20.4811 11.9052C21.0572 12.481 21.4559 12.8795 21.7467 13.3541C22.0038 13.7735 22.1931 14.2307 22.308 14.709C22.4379 15.2503 22.4378 15.814 22.4375 16.6285L22.4375 16.6285L22.4375 16.7353L22.4375 21.9867L25.8371 18.5871C26.2032 18.221 26.7968 18.221 27.1629 18.5871C27.529 18.9532 27.529 19.5468 27.1629 19.9129L22.1629 24.9129L21.5 25.5758ZM6.5 25.5758L7.16242 24.9134L7.16347 24.9123L12.1629 19.9129C12.529 19.5468 12.529 18.9532 12.1629 18.5871C11.7968 18.221 11.2032 18.221 10.8371 18.5871L7.4375 21.9867L7.4375 16.7353C7.4375 15.7734 7.44352 15.4453 7.51521 15.1467C7.58411 14.8597 7.69774 14.5854 7.85195 14.3338C8.01239 14.0719 8.24015 13.8357 8.92027 13.1555L10.9129 11.1629L11.5758 10.5L10.25 9.17416L9.58709 9.83708L7.59445 11.8297L7.51889 11.9052C6.94282 12.481 6.54407 12.8795 6.25325 13.3541C5.99624 13.7735 5.80684 14.2307 5.69202 14.709C5.56208 15.2502 5.56224 15.814 5.56248 16.6284L5.56248 16.6284L5.5625 16.7353L5.5625 21.9867L2.16291 18.5871C1.79679 18.221 1.2032 18.221 0.837085 18.5871C0.470969 18.9532 0.470969 19.5468 0.837087 19.9129L5.83709 24.9129L6.5 25.5758Z" fill="black"/>
	</svg>
</template>

<script>
export default {
	name: "pairingFill"
};
</script>

<style>

</style>