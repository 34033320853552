<template>
	<svg width="29" height="20" 
		viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M12.3039 1.32178C12.9006 0.778758 13.6948 0.5 14.5003 0.5C15.3046 0.5 16.0939 0.77856 16.6947 1.32389C16.7079 1.33588 16.7216 1.34703 16.7357 1.35737C17.3036 1.8926 17.5796 2.57814 17.5796 3.25458C17.5796 3.94551 17.2916 4.64252 16.6968 5.18333C16.6826 5.19629 16.6692 5.20999 16.6566 5.22434L15.934 5.88146C15.8932 5.9185 15.8589 5.96202 15.8323 6.01026L15.8317 6.01137C15.7201 6.21405 15.7622 6.46679 15.9334 6.62244L19.2114 9.60311C19.4021 9.77653 19.6934 9.77653 19.8841 9.60311L29 1.31416V19.9477H0V1.3136L9.11587 9.60255C9.30659 9.77597 9.5979 9.77597 9.78862 9.60255L13.0666 6.62188C13.1709 6.52712 13.2303 6.3928 13.2303 6.25195C13.2303 6.1111 13.1709 5.97678 13.0666 5.88202L12.344 5.22489C12.3315 5.2106 12.3181 5.19695 12.3039 5.18404C11.7091 4.64268 11.421 3.94339 11.421 3.25513C11.421 2.56598 11.7099 1.86191 12.3039 1.32178ZM14.5003 1.77642C14.1115 1.77642 13.7108 1.90931 13.4004 2.19153C13.0875 2.4761 12.9233 2.86205 12.9233 3.25513C12.9233 3.64469 13.0837 4.03429 13.3975 4.32047C13.412 4.33376 13.4272 4.34603 13.4428 4.35729L14.1639 5.01297C14.3547 5.18644 14.6461 5.18638 14.8368 5.01283L15.5573 4.35719C15.5731 4.34582 15.5884 4.33341 15.6031 4.31998C15.9155 4.03422 16.076 3.64673 16.076 3.25513C16.076 2.88094 15.9286 2.51557 15.647 2.23655C15.6324 2.2191 15.6166 2.20256 15.5996 2.18707C15.2893 1.90493 14.8871 1.77642 14.5003 1.77642ZM2.3399 5.30859C2.19346 5.17544 1.98227 5.1412 1.80127 5.22126C1.62026 5.30132 1.50352 5.48061 1.50352 5.67853V18.1713C1.50352 18.4474 1.72738 18.6713 2.00352 18.6713H15.7426C15.9491 18.6713 16.1344 18.5443 16.2089 18.3516C16.2834 18.159 16.2318 17.9403 16.079 17.8014L2.3399 5.30859ZM21.286 10.8705L26.9965 5.67853V16.4276L21.286 10.8705ZM14.4997 7.86142L18.6624 11.6466L18.7035 11.6844L25.3691 18.1713H19.282L11.2217 10.8421L14.4997 7.86142Z" fill="black"/>
		<circle cx="14.5" cy="3.5" r="2.5" 
			fill="black"/>
		<path d="M27.5 4.5L20 10.5L27.5 18V4.5Z" fill="black"/>
		<path d="M26.5 18.5L14.5 7L10 10.5L7.5 9L1 4V19H20.5L26.5 18.5Z" fill="black"/>
	</svg>
</template>

<script>
export default {
	name: "royaltyFill"
};
</script>

<style>

</style>