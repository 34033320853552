<template>
	<svg width="30" height="28" 
		viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M16.4811 1.88172C15.8915 0.112794 13.7496 -0.568931 12.246 0.533733L4.04228 6.5498H3.75C1.67893 6.5498 0 8.22873 0 10.2998V24.1127C0 26.1838 1.67893 27.8627 3.75 27.8627H23.2237C25.2948 27.8627 26.9737 26.1838 26.9737 24.1127V21.4156C28.5911 21.0709 29.8042 19.634 29.8042 17.9139C29.8042 16.1937 28.5911 14.7568 26.9737 14.4121V10.2998C26.9737 8.4693 25.6622 6.94512 23.9276 6.61574L22.2056 2.31076C21.5433 0.655163 19.5048 0.0578903 18.0539 1.09432L16.5716 2.15307L16.4811 1.88172ZM10.4162 6.5498H6.57879L13.1331 1.74334C13.8165 1.24213 14.7901 1.552 15.0581 2.35606L15.2945 3.06527L10.4162 6.5498ZM20.8129 2.86785C20.5118 2.1153 19.5852 1.84381 18.9257 2.31492L12.9969 6.5498H22.2856L20.8129 2.86785ZM23.3933 21.4943H25.4737V24.1127C25.4737 25.3554 24.4664 26.3627 23.2237 26.3627H3.75C2.50736 26.3627 1.5 25.3554 1.5 24.1127V10.2998C1.5 9.05716 2.50736 8.0498 3.75 8.0498H23.2237C24.4664 8.0498 25.4737 9.05716 25.4737 10.2998V14.3334H23.3933C21.4159 14.3334 19.8129 15.9365 19.8129 17.9139C19.8129 19.8913 21.4159 21.4943 23.3933 21.4943ZM21.3129 17.9139C21.3129 16.7649 22.2444 15.8334 23.3933 15.8334H26.2237C27.3727 15.8334 28.3042 16.7649 28.3042 17.9139C28.3042 19.0628 27.3727 19.9943 26.2238 19.9943H23.3933C22.2443 19.9943 21.3129 19.0628 21.3129 17.9139ZM11.1867 12.4303C11.0461 12.2896 10.8553 12.2106 10.6564 12.2106C10.4575 12.2106 10.2667 12.2896 10.1261 12.4303L5.88045 16.6759C5.58756 16.9688 5.58756 17.4436 5.88045 17.7365C6.17335 18.0294 6.64822 18.0294 6.94111 17.7365L9.90641 14.7712V21.4518C9.90641 21.866 10.2422 22.2018 10.6564 22.2018C11.0706 22.2018 11.4064 21.866 11.4064 21.4518V14.7712L14.3717 17.7365C14.6646 18.0294 15.1395 18.0294 15.4324 17.7365C15.7253 17.4436 15.7253 16.9688 15.4324 16.6759L11.1867 12.4303Z" fill="#B8B8B8"/>
	</svg>
</template>

<script>
export default {
	name: "transaction-default"
};
</script>

<style>

</style>