<template>
	<svg width="30" height="30" viewBox="0 0 30 30" 
		fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M26.25 11.0916V18.25C26.25 21.0503 26.25 22.4504 25.705 23.52C25.2257 24.4608 24.4608 25.2257 23.52 25.7051C22.4504 26.25 21.0503 26.25 18.25 26.25H11.75C8.94975 26.25 7.54962 26.25 6.48006 25.7051C5.53925 25.2257 4.77435 24.4608 4.29498 23.52C3.75001 22.4504 3.75001 21.0503 3.75001 18.25L3.75 11.0916C3.75 10.0147 3.75 9.47626 3.91397 9.00089C4.05896 8.58052 4.2956 8.19763 4.60675 7.87995C4.9586 7.5207 5.4402 7.27991 6.40339 6.79831L13.569 3.21554C14.0937 2.95317 14.3561 2.82199 14.6313 2.77036C14.875 2.72464 15.1251 2.72464 15.3689 2.77037C15.6441 2.822 15.9064 2.95318 16.4311 3.21554L23.5966 6.79831C24.5598 7.2799 25.0414 7.5207 25.3933 7.87994C25.7044 8.19763 25.9411 8.58051 26.0861 9.00089C26.25 9.47625 26.25 10.0147 26.25 11.0916Z" stroke="#B8B8B8" stroke-width="1.5" 
			stroke-linecap="round"/>
	</svg>

</template>

<script>
export default {
	name: "home"
};
</script>

<style>

</style>