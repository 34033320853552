import axios from 'axios';

let prefix = "/announcement"

const AnnouncementApi = {
	getAnnouncementList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getAnnouncementInfo(id, payload){
		return axios.get( prefix + "/get/"+id, {params: payload})
	},
}

export default AnnouncementApi;