<template>
	<svg width="30" height="30" viewBox="0 0 30 30" 
		fill="none" xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="15" cy="15" r="14.25" 
			stroke="#B8B8B8" stroke-width="1.5"
		/>
		<path fill-rule="evenodd" clip-rule="evenodd" 
			d="M11.2385 8.56024L11.7642 8.03459L11.7736 8.02519L15.7344 4.0644C16.0273 3.7715 16.5021 3.7715 16.795 4.0644C17.0879 4.35729 17.0879 4.83216 16.795 5.12506L14.1099 7.8102L16.8063 7.8102H16.8192H16.8193C17.6932 7.8102 18.3655 7.8102 18.885 7.82511C19.4002 7.83989 19.814 7.86998 20.1528 7.94452C23.9338 8.77641 25.8289 13.0316 23.9179 16.3985C23.7467 16.7002 23.4922 17.0279 23.1586 17.4207C22.8221 17.8168 22.3723 18.3166 21.7877 18.9662L21.7791 18.9758L20.6641 17.9723C21.2591 17.3112 21.6945 16.8274 22.0153 16.4496C22.3406 16.0666 22.5163 15.8291 22.6134 15.6581C24.0259 13.1695 22.6252 10.0244 19.8305 9.40948C19.6384 9.36722 19.3444 9.33891 18.842 9.32449C18.3466 9.31028 17.6958 9.3102 16.8063 9.3102L14.1098 9.3102L16.795 11.9954C17.0879 12.2883 17.0879 12.7632 16.795 13.0561C16.5021 13.349 16.0273 13.349 15.7344 13.0561L11.7689 9.09057L11.2385 8.56024ZM10.7162 21.5903C7.92156 20.9755 6.52084 17.8303 7.93334 15.3417C8.03041 15.1707 8.20608 14.9332 8.53141 14.5502C8.85226 14.1724 9.28758 13.6886 9.88258 13.0275L8.76763 12.0241L8.75904 12.0336L8.75903 12.0336C8.1744 12.6832 7.72462 13.183 7.38814 13.5791C7.05449 13.972 6.80005 14.2996 6.62882 14.6013C4.71779 17.9682 6.61289 22.2234 10.3939 23.0553C10.7327 23.1298 11.1465 23.1599 11.6617 23.1747C12.1812 23.1896 12.8534 23.1896 13.7272 23.1896H13.7274H13.7404H16.4368L13.7517 25.8748C13.4588 26.1677 13.4588 26.6425 13.7517 26.9354C14.0446 27.2283 14.5194 27.2283 14.8123 26.9354L18.7779 22.9699L19.3082 22.4396L18.7779 21.9093L14.8123 17.9437C14.5194 17.6508 14.0446 17.6508 13.7517 17.9437C13.4588 18.2366 13.4588 18.7115 13.7517 19.0044L16.4369 21.6896H13.7404C12.8509 21.6896 12.2001 21.6896 11.7047 21.6753C11.2024 21.6609 10.9083 21.6326 10.7162 21.5903Z" 
			fill="#B8B8B8"
		/>
	</svg>
</template>

<script>
export default {
	name: "directSponsor"
};
</script>

<style>

</style>