<template>
	<svg width="30" height="30" viewBox="0 0 30 30" 
		fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" 
			d="M15.676 1.87758C15.2292 1.79374 14.7706 1.79374 14.3238 1.87757C13.8161 1.97281 13.3463 2.20812 12.749 2.5073L12.6446 2.55956L6.84048 5.4616L6.68773 5.53792C5.55129 6.10556 4.76618 6.49771 4.17776 7.09848C3.65918 7.62796 3.26478 8.2661 3.02312 8.96673C2.74892 9.7617 2.74928 10.6393 2.7498 11.9096L2.74985 12.0804L2.74985 15.5833L2.74985 15.628C2.74985 17.457 2.74984 18.8909 2.844 20.0434C2.93988 21.2169 3.13836 22.1864 3.58547 23.0639C4.3205 24.5065 5.49335 25.6793 6.93593 26.4143C7.81343 26.8615 8.78291 27.0599 9.95643 27.1558C11.1089 27.25 12.5428 27.25 14.3717 27.25H14.3717H14.4165H15.5832H15.628H15.628C17.4569 27.25 18.8908 27.25 20.0433 27.1558C21.2168 27.0599 22.1863 26.8615 23.0638 26.4143C24.5064 25.6793 25.6792 24.5065 26.4142 23.0639C26.8614 22.1864 27.0598 21.2169 27.1557 20.0434C27.2499 18.8909 27.2499 17.457 27.2499 15.628V15.628V15.5833V12.0803L27.2499 11.9096V11.9096C27.2504 10.6393 27.2508 9.76168 26.9766 8.96672C26.7349 8.2661 26.3405 7.62796 25.822 7.09848C25.2335 6.49771 24.4484 6.10555 23.312 5.53792L23.1593 5.46159L17.3552 2.55957L17.2508 2.50731C16.6535 2.20813 16.1837 1.97282 15.676 1.87758Z" fill="black"/>
	</svg>
</template>

<script>
export default {
	name: "directSponsor"
};
</script>

<style>

</style>