import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import( '@/views/_general/Comingsoon')
const Error404 = () => import( '@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import( '@/views/_general/Login')
const UserRegister = () => import( '@/views/_general/UserRegister')
const ResetPassword = () => import('@/views/_general/ResetPassword')

const Home = () => import( '@/views/_general/Home')

const UserProfile = () => import( '@/views/Users/UserProfile')

const PairingNetworkPage = () => import( '@/views/PairingTrees/PairingNetworkPage')

const RoyaltyPage = () => import( '@/views/Royalties/RoyaltyPage')

const DirectSponsorPage = () => import( '@/views/DirectSponsors/DirectSponsorPage')

const NetworkInfoPage = () => import( '@/views/Networkings/NetworkInfoPage')

const MintingPackPage = () => import( '@/views/MintingPacks/MintingPackPage')

const AnnouncementPage = () => import( '@/views/Announcements/AnnouncementPage')

const TransactionPage = () => import( '@/views/Transactions/TransactionPage')

const ShareBuildPage = () => import( '@/views/ShareBuilds/ShareBuildPage')

const StakePage = () => import( '@/views/Stakes/StakePage')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [ 1,5 ], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },

	{ path: '/pairing-tree/info', name: 'pairing_tree.info', component: PairingNetworkPage, meta: { auth: true, title: 'Pairing Network', has_minting: true, exclusive_user: false } },

	{ path: '/direct-sponsor/info', name: 'direct-sponsor.page', component: DirectSponsorPage, meta: { auth: true, title: 'Direct Referral Page', has_minting: true, exclusive_user: false} },

	{ path: '/royalty/info', name: 'royalty.info', component: RoyaltyPage, meta: { auth: true, title: 'Royalty Info', has_minting: true, exclusive_user: false } },

	{ path: '/user/profile', name: 'user.profile', component: UserProfile, meta: { auth: true, title: 'User Profile', has_minting: true, exclusive_user: false } },
	
	{ path: '/networking/info', name: 'networking.info', component: NetworkInfoPage, meta: { auth: true, title: 'Networking Info', has_minting: true, exclusive_user: false } },

	{ path: '/starter-pack', name: 'starter-pack.page', component: MintingPackPage, meta: { auth: true, title: 'Starter Pack', exclusive_user: false } },

	{ path: '/news', name: 'news.page', component: AnnouncementPage, meta: { auth: true, title: 'News', has_minting: true, exclusive_user: false } },
	
	{ path: '/transactions', name: 'transactions.page', component: TransactionPage, meta: { auth: true, title: 'Transactions', has_minting: true, exclusive_user: false } },

	{ path: '/burning-royalty/info', name: 'share_build.info', component: ShareBuildPage, meta: { auth: true, title: 'Burning Royalty', has_minting: true, exclusive_user: false } },
	
	{ path: '/staking/info', name: 'staking.info', component: StakePage, meta: { auth: true, title: 'Stake', has_minting: true, exclusive_user: false } },
]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/register', name: 'register', component: UserRegister, meta: { auth: null, title: 'Register' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },

	{ path: '/register-success', name: 'register_success', component: Login, meta: { auth: null, title: 'Register Successful' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	next()
});

router.onError(error => {
	if (/loading chunk \d* failed./i.test(error.message)) {
		router.push('/login')
	}
})

export default router