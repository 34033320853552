<template>
	<svg width="29" height="29" 
		viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M14.875 0.75C7.07451 0.75 0.75 7.07355 0.75 14.874C0.75 22.6745 7.07451 28.9981 14.875 28.9981C22.6755 28.9981 29 22.6745 29 14.874C29 7.07355 22.6755 0.75 14.875 0.75ZM12.933 18.0973V18.3074H15.8521V18.0973C15.8579 17.5264 15.931 17.0471 16.0712 16.6593C16.2173 16.2662 16.4394 15.9242 16.7374 15.6334C17.0413 15.3425 17.4299 15.0625 17.9033 14.7932C18.4409 14.497 18.9055 14.1604 19.2971 13.7834C19.6886 13.4064 19.9896 12.9755 20.2 12.4908C20.4162 12.0061 20.5243 11.4567 20.5243 10.8428C20.5243 9.93258 20.2847 9.14627 19.8055 8.48382C19.3321 7.81599 18.6688 7.30166 17.8156 6.94082C16.9683 6.57997 15.9865 6.39955 14.8703 6.39955C13.8476 6.39955 12.9126 6.57189 12.0652 6.91658C11.2237 7.26127 10.5458 7.77829 10.0315 8.46767C9.5231 9.15704 9.25428 10.0134 9.22506 11.0367H12.3457C12.3749 10.5304 12.5094 10.113 12.749 9.78447C12.9944 9.45056 13.3012 9.20282 13.6694 9.04125C14.0434 8.87429 14.4378 8.79081 14.8528 8.79081C15.3027 8.79081 15.7089 8.87698 16.0712 9.04932C16.4394 9.22167 16.7316 9.46402 16.9478 9.77639C17.164 10.0888 17.2722 10.4577 17.2722 10.8832C17.2722 11.2602 17.1903 11.6022 17.0267 11.9091C16.8631 12.2107 16.6381 12.4854 16.3517 12.7332C16.0712 12.9755 15.7527 13.199 15.3963 13.4037C14.8761 13.6999 14.4349 14.0257 14.0726 14.3812C13.7103 14.7313 13.4298 15.1944 13.2311 15.7707C13.0382 16.347 12.9389 17.1225 12.933 18.0973ZM13.0996 22.8314C13.4794 23.1761 13.9323 23.3484 14.4583 23.3484C14.8089 23.3484 15.1274 23.2703 15.4138 23.1141C15.706 22.9525 15.9397 22.7371 16.1151 22.4678C16.2962 22.1985 16.3868 21.8996 16.3868 21.5711C16.3868 21.0864 16.1939 20.6717 15.8082 20.327C15.4284 19.9823 14.9784 19.81 14.4583 19.81C13.9323 19.81 13.4794 19.9823 13.0996 20.327C12.7197 20.6717 12.5298 21.0864 12.5298 21.5711C12.5298 22.0666 12.7197 22.4867 13.0996 22.8314Z" fill="black"/>
	</svg>
</template>

<script>
export default {
	name: "directSponsor"
};
</script>

<style>

</style>