<template>
	<svg width="30" height="30" 
		viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M1.75 15C1.75 7.68223 7.68223 1.75 15 1.75C22.3178 1.75 28.25 7.68223 28.25 15C28.25 18.1012 27.1838 20.9545 25.3991 23.2118C23.5615 20.9658 21.1483 19.3306 18.5443 18.5377C20.6121 17.3212 22 15.0727 22 12.5C22 8.63401 18.866 5.5 15 5.5C11.134 5.5 7.99996 8.63401 7.99996 12.5C7.99996 15.0727 9.3878 17.3212 11.4556 18.5377C8.85168 19.3305 6.43846 20.9658 4.60084 23.2118C2.81614 20.9545 1.75 18.1012 1.75 15ZM5.61022 24.3485C8.00878 26.7576 11.3307 28.25 15 28.25C18.6692 28.25 21.9912 26.7576 24.3897 24.3485C21.9975 21.3178 18.4769 19.5 15 19.5C11.523 19.5 8.00249 21.3178 5.61022 24.3485ZM15 7C11.9624 7 9.49996 9.46243 9.49996 12.5C9.49996 15.5376 11.9624 18 15 18C18.0375 18 20.5 15.5376 20.5 12.5C20.5 9.46243 18.0375 7 15 7Z" fill="black"/>
	</svg>
</template>

<script>
export default {
	name: "userFill"
};
</script>

<style>

</style>