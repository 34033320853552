import ja from 'vuetify/lib/locale/ja'

const nav = {
	"Dashboard": "ダッシュボード",
	"User Profile": "ユーザープロフィール",
	"Pairing Network": "ペアリングネットワーク",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "直接スポンサー",
	"Royalty": "王族",
	"Running Royalty": "ランニングロイヤルティー",
	"1Move Library": "1Move書館の移動",
	"Transactions": "取引",
	"News": "ニュース",
	"Starter Pack": "スターターパック",
	"Features": "機能",
	"Options": "オプション",
	"Language Switcher": "言語スイッチャー",
	"Log Out": "ログアウトする",
}

const token_transaction = {
	"Token Transactions": "トークントランザクション",
	"Type": "種類",
	"Token Type": "トークンの種類",
	"Amount": "量",
	"Created at": "作成日時",
}

const starter_pack = {
	"NFT SHOE": "NFTシューズ",
	"Mint": "として",
	"Walker": "歩行器",
	"Jogger": "ジョガー",
	"Runner": "ランナー",
	"Selected": "選択",
	"Total": "トータル",
	"Back": "戻る",
	"This feature is currently unavailable.": "この機能は現在利用できません",
	"Click to Download Networking Info": "クリックしてネットワーク情報をダウンロード",
	"Select one shoe": "靴を1足お選びください",
	"Select three shoes": "靴を3つ選ぶ",
	"OR": "また",
	"Minting Pack": "ミントパック",
}

const royalty = {
	"Royalty Payout": "ロイヤリティの支払い",
	"Last updated": "最終更新日時",
	"Collect Payout": "ペイアウトを集める",
	"Are you sure to continue?": "続行してもよろしいですか？",
	"Are you sure you want to collect the payout?": "支払いを受け取りますか?",
	"Warning": "警告"
}

const pairing_tree = {
	"Pairing Tree": "ペアリングツリー",
	"Flushing Schedule": "フラッシングスケジュール",
	"Next Flushing Date": "次のフラッシング日",
	"Team": "チーム",
	"Sneakers": "スニーカー",
	"Starter Pack Date": "スターター パックの日付",
	"No Referral": "紹介なし",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "紹介データ",
	"Direct Referral Tree": "直接スポンサー",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "結果が見つかりません",
	"No Starter Pack": "スターターパックなし",
	"Referrals By Month": "月ごとの紹介",
	"Referral": "照会",
	"Left Tree": "左の木",
	"Right Tree": "右の木",
}

const date = {
	"January": "1 月",
	"February": "2 月",
	"March": "3 月",
	"April": "4 月",
	"May": "5 月",
	"June": "6 月",
	"July": "7 月",
	"August": "8 月",
	"September": "9 月",
	"October": "10 月",
	"November": "11 月",
	"December": "12 月",
}

const home = {
	"Total Referral": "総紹介数",
	"Total Referral (Month)": "総紹介数(月)",
	"Total Pairing Payout (Month)": "ペアリング支払いの合計(月)",
	"Total Direct Referral Payout (Month)": "スポンサーへの直接支払い総額(月)",
	"Direct Referral Payout": "スポンサーへの直接支払い総額",
	"Total Royalty Payout (Month)": "ロイヤリティ支払い総額 (月)",
	"Welcome Back": "おかえり",
	"Your current ranking is": "あなたの現在のランキングは",
	"Pairing Payout": "ペアリングペイアウト",
	"Total Accumulated": "累計",
	"Direct Referral": "ダイレクトスポンサー",
	"Royalty Payout": "ロイヤルティの支払い",
	"Total Members Under Pairing & Direct Referral Network": "ペアリングおよびダイレクト スポンサー ネットワークの合計メンバー数",
	"Total Starter Pack Under Pairing & Direct Referral Network": "ペアリング & ダイレクト スポンサー ネットワークのトータル スターター パック",
	"Total Registered Account Under Pairing & Direct Referral Network": "ペアリングおよびダイレクト スポンサー ネットワークに登録されているアカウントの合計",
	"Token": "トークン",
	"Items Owned": "所持アイテム",
	"Artifacts": "アーティファクト",
	"Shoeboxes": "靴箱",
	"Referrals By Country": "国別の紹介",
	"News Feed": "ニュースフィード",
}

const user = {
	"Email": "電子メール",
	"Name": "Name",
	"Ranking": "ランキング",
	"Left Side Pairing Amount": "左サイドペアリング量",
	"Right Side Pairing Amount": "右側のペアリング量",
	"Referred By": "参照元",
	"Joined at": "で参加",
	"Status": "地位",
	"Active": "能動",
	"Deactivated": "非活動化",
	"Uncalculated": "未計算",
	"Pending": "保留中",
	"Rank": "ランク",
	"Registered On": "登録日",
	"Activated On": "アクティベートオン",
	"Total Distance": "総距離",
	"Total Income": "総収入",
	"Total Runs": "総実行数",
	"times": "回",
	"Total Run Time": "総実行時間",
	"hours": "時間",
}

const misc = {
	"Filters": "フィルター",
	"Type": "種類",
	"Apply": "申し込む",
}

const user_token = {
	"Next Flushing Date": "次のフラッシング日",
	"Pairing Tree": "ペアリングツリー",
	"Flushing Schedule": "フラッシングスケジュール",
	"No Referral": "紹介なし",
	"Receive pairing payout": "ペアリングの支払いを受け取る",
	"Payout Details": "支払いの詳細",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "左スニーカー",
	"Right Sneakers": "右のスニーカー",
	"Total Sneakers": "トータルスニーカー",
	"Payout in USDT": "米ドルでの支払い",
	"Payout in 1MT": "1MTでの支払い",
	"USDT to 1RT Conversion Rate": "USDTから1RTへの変換率",
	"1MT to 1RT Conversion Rate": "1MTから1RTへの変換率",
	"1RT Received": "1RT 受信済み",
	"Pairing Carry Forward Details": "ペアリング 繰越詳細",
	"Pairing Flushing Details": "ペアリングフラッシングの詳細",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "ステーキング",
	"Staking Info": "ステーキング情報",
	"Staking Portfolio": "ステーキングポートフォリオ",
	"Staking History": "ステーキング履歴",
	"Next Staking": "次のステーキング",
	"Buy Now": "今すぐ購入",
	"Buy Lot": "ロットを購入",
	"Buy Lot Amount": "ロットの数量を購入",
	"Total Amount": "合計金額",
	"Buy": "購入",
	"Lots": "ロット",
	"Cancel": "キャンセル",
	"Staking Availability Table": "ステーキング可用性表",
	"Total 1MT Pool of Funds": "合計1MTファンドプール",
	"Coming Soon": "近日公開",
	"Lot Amount": "ロットの数量",
	"Amount Purchased": "購入金額",
	"Maturity Date": "満期日",
	"1RT Earning": "1RTの収益",
	"BNB Earning": "BNBの収益",
	"Total Staking": "合計ステーキング",
	"Staking Live": "ライブステーキング",
	"Ends On": "終了日",
	"OTP Code": "OTPコード",
	"Request OTP": "OTPの要求",
	"OTP code sent.": "OTPコードが送信されました。",
	"Sold Out": "完売",
	"Sell": "売却する",
	"Sell Lot": "ロットを売却する",
	"Available": "利用可能",
	"Penalty": "ペナルティ",
	"Final Amount": "最終金額",
	"1RT Payout": "1RT 支払い",
	"Unsubscribe Lot Amount": "解約するロットの量",
	"Unsubscribe Lot": "解約するロット",
	"Unsubscribe": "解約する",
	"Month": "月",
	"Year": "年",
	"Total Point": "総ポイント",
	"Point": "ポイント",
	"BNB Point Ratio": "BNBポイント比率",
	"Staking Amount": "ステーキング金額",
	"BNB Received": "受け取ったBNB",
}

export default {
	$vuetify: {
		...ja
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}