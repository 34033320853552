import axios from 'axios';

let prefix = "/pairing-tree"

const PairingTreeApi = {
	
	getPairingTreeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getFlushingDate(payload){
		return axios.get( prefix + "/flushing/info", {params: payload})
	},
}

export default PairingTreeApi;