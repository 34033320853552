import zhHans from 'vuetify/lib/locale/zh-Hans'

const nav = {
	"Dashboard": "仪表板",
	"User Profile": "用户配置文件",
	"Pairing Network": "配对网络",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "直接赞助商",
	"Royalty": "王权",
	"Running Royalty": "运营版税",
	"1Move Library": "1Move图书馆",
	"Transactions": "交易",
	"News": "消息",
	"Starter Pack": "入门包",
	"Features": "功能",
	"Options": "选项",
	"Language Switcher": "语言切换器",
	"Log Out": "注销",
}

const token_transaction = {
	"Token Transactions": "代币交易",
	"Type": "类型",
	"Token Type": "令牌类型",
	"Amount": "数量",
	"Created at": "创建于",
}

const starter_pack = {
	"NFT SHOE": "NFT 鞋",
	"Mint": "如",
	"Walker": "行走者",
	"Jogger": "慢跑者",
	"Runner": "跑步者",
	"Selected": "选择",
	"Total": "总",
	"Back": "返回",
	"Click to Download Networking Info": "点击下载网络信息",
	"This feature is currently unavailable.": "此功能目前无法使用.",
	"Select one shoe": "选择一只鞋",
	"Select three shoes": "选择三只鞋",
	"OR": "或者",
	"Minting Pack": "造币包",
}

const royalty = {
	"Royalty Payout": "版税支付",
	"Last updated": "上次更新时间",
	"Collect Payout": "收款",
	"Are you sure to continue?": "你确定要继续吗？",
	"Are you sure you want to collect the payout?": "您确定要收取款项吗？",
	"Warning": "警告"
}

const pairing_tree = {
	"Pairing Tree": "配对树",
	"Flushing Schedule": "刷新时间表",
	"Next Flushing Date": "下一个刷新日期",
	"Team": "团队",
	"Sneakers": "球鞋",
	"Starter Pack Date": "入门包日期",
	"No Referral": "无推荐",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "推荐数据",
	"Direct Referral Tree": "直属推荐树",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "未找到结果",
	"No Starter Pack": "无入门包",
	"Referrals By Month": "按月推荐",
	"Referral": "转介",
	"Left Tree": "左树",
	"Right Tree": "右树",
}

const date = {
	"January": "一月",
	"February": "二月",
	"March": "三月",
	"April": "四月",
	"May": "五月",
	"June": "六月",
	"July": "七月",
	"August": "八月",
	"September": "九月",
	"October": "十月",
	"November": "十一月",
	"December": "十二月",
}

const home = {
	"Total Referral": "总推荐",
	"Total Referral (Month)": "总推荐（月",
	"Total Pairing Payout (Month)": "总配对支付（月",
	"Total Direct Referral Payout (Month)": "直接赞助商付款总额（月）",
	"Direct Referral Payout": "直接赞助商付款总额",
	"Total Royalty Payout (Month)": "总版税支出（月）",
	"Welcome Back": "欢迎回来",
	"Your current ranking is": "你目前的排名是",
	"Pairing Payout": "配对支付",
	"Total Accumulated": "总累计",
	"Direct Referral": "直接赞助商",
	"Royalty Payout": "特许权使用费",
	"Total Members Under Pairing & Direct Referral Network": "配对和直接赞助网络下的会员总数",
	"Total Starter Pack Under Pairing & Direct Referral Network": "配对和直接赞助商网络下的总入门包",
	"Total Registered Account Under Pairing & Direct Referral Network": "配对和直接赞助网络下的注册账户总数",
	"Token": "代币",
	"Items Owned": "拥有的物品",
	"Artifacts": "神器",
	"Shoeboxes": "鞋盒",
	"Referrals By Country": "按国家推荐",
	"News Feed": "动态消息",
}

const user = {
	"Email": "电子邮件",
	"Name": "Name",
	"Ranking": "排名",
	"Left Side Pairing Amount": "左侧配对量",
	"Right Side Pairing Amount": "右侧配对金额",
	"Referred By": "推荐人",
	"Joined at": "加入于",
	"Status": "地位",
	"Active": "积极",
	"Deactivated": "停用",
	"Uncalculated": "未计算",
	"Pending": "待定",
	"Rank": "秩",
	"Registered On": "注册于",
	"Activated On": "激活时间",
	"Total Distance": "总距离",
	"Total Income": "总收入",
	"Total Runs": "总运行",
	"times": "次",
	"Total Run Time": "总运行时间",
	"hours": "小时",
}

const misc = {
	"Filters": "过滤",
	"Type": "类型",
	"Apply": "应用",
}

const user_token = {
	"Next Flushing Date": "下一个刷新日期",
	"Pairing Tree": "配对树",
	"Flushing Schedule": "刷新时间表",
	"No Referral": "无推荐",
	"Receive pairing payout": "接收配对付款",
	"Payout Details": "付款详细信息",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "左运动鞋",
	"Right Sneakers": "右运动鞋",
	"Total Sneakers": "运动鞋总数",
	"Payout in USDT": "以USDT支付",
	"Payout in 1MT": "以1MT支付",
	"USDT to 1RT Conversion Rate": "USDT 到 1RT 转换率",
	"1MT to 1RT Conversion Rate": "1MT 到 1RT 转换率",
	"1RT Received": "1RT 已接收",
	"Pairing Carry Forward Details": "配对结转详细信息",
	"Pairing Flushing Details": "配对冲洗详细信息",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "质押",
	"Staking Info": "质押信息",
	"Staking Portfolio": "质押投资组合",
	"Staking History": "质押历史",
	"Next Staking": "下一个质押",
	"Buy Now": "立即购买",
	"Buy Lot": "购买批次",
	"Buy Lot Amount": "购买批次金额",
	"Total Amount": "总金额",
	"Buy": "购买",
	"Lots": "批次",
	"Cancel": "取消",
	"Staking Availability Table": "质押可用性表",
	"Total 1MT Pool of Funds": "1MT资金池总额",
	"Coming Soon": "即将推出",
	"Lot Amount": "批次金额",
	"Amount Purchased": "已购买金额",
	"Maturity Date": "到期日",
	"1RT Earning": "1RT收益",
	"BNB Earning": "BNB收益",
	"Total Staking": "总质押",
	"Staking Live": "实时质押",
	"Ends On": "结束日期",
	"OTP Code": "OTP验证码",
	"Request OTP": "请求OTP",
	"OTP code sent.": "已发送OTP验证码。",
	"Sold Out": "已售罄",
	"Sell": "卖出",
	"Sell Lot": "卖出批次",
	"Available": "可用",
	"Penalty": "罚款",
	"Final Amount": "最终金额",
	"1RT Payout": "1RT 支付",
	"Unsubscribe Lot Amount": "取消订阅的批次数量",
	"Unsubscribe Lot": "取消订阅的批次",
	"Unsubscribe": "取消订阅",
	"Month": "月",
	"Year": "年",
	"Total Point": "总积分",
	"Point": "积分",
	"BNB Point Ratio": "BNB积分比例",
	"Staking Amount": "质押金额",
	"BNB Received": "收到的BNB",
}

export default {
	$vuetify: { 
		...zhHans,
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}