import id from 'vuetify/lib/locale/id'

const nav = {
	"Dashboard": "Dasbor",
	"User Profile": "Profil Pengguna",
	"Pairing Network": "Jaringan Pemasangan",
	"Burning Royalty": "Burning Royalty",
	"Direct Referral": "Referral Langsung",
	"Royalty": "Royalti",
	"Running Royalty": "Royalti Berjalan",
	"1Move Library": "1Move Perpustakaan",
	"Transactions": "Transaksi",
	"News": "Berita",
	"Starter Pack": "paket permula",
	"Features": "Pilihan",
	"Options": "Pilihan",
	"Language Switcher": "Ganti Bahasa",
	"Log Out": "Log Out",
}

const token_transaction = {
	"Token Transactions": "Transaksi Token",
	"Type": "Jenis",
	"Token Type": "Jenis Token",
	"Amount": "Jumlah",
	"Created at": "Dibuat di",
}

const starter_pack = {
	"NFT SHOE": "Sepatu NFT",
	"Mint": "Sebagai",
	"Walker": "Walker",
	"Jogger": "Pelari",
	"Runner": "Pelari",
	"Selected": "Pilih",
	"Total": "Seluruh",
	"Back": "Belakang",
	"This feature is currently unavailable.": "Fitur pembelian paket permula saat ini tidak tersedia. Maaf atas ketidaknyamanan yang ditimbulkan.",
	"Click to Download Networking Info": "Klik untuk Mengunduh Info Jaringan",
	"Select one shoe": "Pilih satu sepatu",
	"Select three shoes": "Pilih tiga sepatu",
	"OR": "ATAU",
	"Minting Pack": "Paket Mining",
}

const royalty = {
	"Royalty Payout": "Pembayaran Royalti",
	"Last updated": "Terakhir diperbarui",
	"Collect Payout": "Kumpulkan Pembayaran",
	"Are you sure to continue?": "Apakah Anda yakin untuk melanjutkan?",
	"Are you sure you want to collect the payout?": "Apakah Anda yakin ingin mengumpulkan pembayaran?",
	"Warning": "Peringatan"
}

const pairing_tree = {
	"Pairing Tree": "Pohon Pasangan",
	"Flushing Schedule": "Jadwal Pembilasan",
	"Next Flushing Date": "Tanggal Pembilasan Berikutnya",
	"Team": "Tim",
	"Sneakers": "Sepatu kets",
	"Starter Pack Date": "Tanggal Paket Pemula",
	"No Referral": "Tidak Ada Rujukan",
	"Total Left": "Total Left",
	"Total Right": "Total Right",
}

const direct_sponsor = {
	"Referrals Data": "Data Rujukan",
	"Direct Referral Tree": "Referral Langsung",
	"Direct Referral List": "Direct Referral List",
	"No Result Found.": "Tidak Ada Hasil yang Ditemukan.",
	"No Starter Pack": "Tidak Ada paket permula",
	"Referrals By Month": "Referensi Berdasarkan Bulan",
	"Referral": "Rujukan",
	"Left Tree": "Pohon Kiri",
	"Right Tree": "Pohon Kanan",
}

const date = {
	"January": "Januari",
	"February": "Februari",
	"March": "Maret",
	"April": "April",
	"May": "Mei",
	"June": "Juni",
	"July": "Juli",
	"August": "Agustus",
	"September": "September",
	"October": "Oktober",
	"November": "November",
	"December": "Desember",
}

const home = {
	"Total Referral": "Total Referral",
	"Total Referral (Month)": "Total Referral (Bulan)",
	"Total Pairing Payout (Month)": "Total Pembayaran Pasangan (Bulan)",
	"Total Direct Referral Payout (Month)": "Total Pembayaran Referral Langsung (Bulan)",
	"Direct Referral Payout": "Pembayaran Referral Langsung",
	"Total Royalty Payout (Month)": "Total Pembayaran Royalti (Bulan)",
	"Welcome Back": "Selamat Datang kembali",
	"Your current ranking is": "Peringkat Anda saat ini adalah",
	"Pairing Payout": "Memasangkan Pembayaran",
	"Total Accumulated": "Total Akumulasi",
	"Direct Referral": "Referral Langsung",
	"Royalty Payout": "Pembayaran Royalti",
	"Total Members Under Pairing & Direct Referral Network": "Jumlah Member Under Pairing & Jaringan Referral Langsung",
	"Total Starter Pack Under Pairing & Direct Referral Network": "Jumlah Starter Pack Under Pairing & Direct Referral Network",
	"Total Registered Account Under Pairing & Direct Referral Network": "Jumlah Akun Terdaftar Di Bawah Jaringan Pairing & Referral Langsung",
	"Token": "Token",
	"Items Owned": "Barang yang Dimiliki",
	"Artifacts": "Artefak",
	"Shoeboxes": "Kotak sepatu",
	"Referrals By Country": "Referensi Berdasarkan Negara",
	"News Feed": "Umpan Berita",
}

const user = {
	"Email": "Email",
	"Name": "Name",
	"Ranking": "Peringkat",
	"Left Side Pairing Amount": "Jumlah Pasangan Sisi Kiri",
	"Right Side Pairing Amount": "Jumlah Pasangan Sisi Kanan",
	"Referred By": "Dirujuk Oleh",
	"Joined at": "Bergabung di",
	"Status": "Keadaan",
	"Active": "Aktif",
	"Deactivated": "Dinonaktifkan",
	"Uncalculated": "Tidak Dihitung",
	"Pending": "Tertunda",
	"Rank": "Pangkat",
	"Registered On": "Terdaftar Aktif",
	"Activated On": "Diaktifkan Aktif",
	"Total Distance": "Jarak Total",
	"Total Income": "Jumlah pemasukan",
	"Total Runs": "Lari Total",
	"times": "waktu",
	"Total Run Time": "Total Waktu Berjalan",
	"hours": "jam",
}

const misc = {
	"Filters": "Filter",
	"Type": "Jenis",
	"Apply": "Berlaku",
}

const user_token = {
	"Next Flushing Date": "Tanggal Pembilasan Berikutnya",
	"Pairing Tree": "Pohon Pasangan",
	"Flushing Schedule": "Jadwal Pembilasan",
	"No Referral": "Tidak Ada Rujukan",
	"Receive pairing payout": "Menerima pembayaran pasangan",
	"Payout Details": "Detail Pembayaran",
	"Download Payout Details": "Download Payout Details",
	"Left Sneakers": "Sepatu Kets Kiri",
	"Right Sneakers": "Sepatu Kets Kanan",
	"Total Sneakers": "Total Sepatu Kets",
	"Payout in USDT": "Pembayaran dalam USDT",
	"Payout in 1MT": "Pembayaran dalam 1MT",
	"USDT to 1RT Conversion Rate": "Tingkat Konversi USDT ke 1RT",
	"1MT to 1RT Conversion Rate": "Tingkat Konversi 1MT ke 1RT",
	"1RT Received": "1RT Diterima",
	"Pairing Carry Forward Details": "Memasangkan Detail Carry Forward",
	"Pairing Flushing Details": "Detail Pembilasan Pasangan",
}

const burning_royalty = {
	"Burning Royalty Tree": "Burning Royalty Tree",
}

const staking = {
	"Staking": "Staking",
	"Staking Info": "Info Staking",
	"Staking Portfolio": "Portofolio Staking",
	"Staking History": "Riwayat Staking",
	"Next Staking": "Staking Berikutnya",
	"Buy Now": "Beli Sekarang",
	"Buy Lot": "Beli Lot",
	"Buy Lot Amount": "Jumlah Beli Lot",
	"Total Amount": "Jumlah Total",
	"Buy": "Beli",
	"Lots": "Lot",
	"Cancel": "Batal",
	"Staking Availability Table": "Tabel Ketersediaan Staking",
	"Total 1MT Pool of Funds": "Total 1MT Pool Dana",
	"Coming Soon": "Segera Hadir",
	"Lot Amount": "Jumlah Lot",
	"Amount Purchased": "Jumlah Dibeli",
	"Maturity Date": "Tanggal Jatuh Tempo",
	"1RT Earning": "Pendapatan 1RT",
	"BNB Earning": "Pendapatan BNB",
	"Total Staking": "Jumlah Staking",	
	"Staking Live": "Staking Langsung",
	"Ends On": "Berakhir pada",
	"OTP Code": "Kode OTP",
	"Request OTP": "Minta OTP",
	"OTP code sent.": "Kode OTP terkirim.",
	"Sold Out": "Habis Terjual",
	"Sell": "Jual",
	"Sell Lot": "Jual Lot",
	"Available": "Tersedia",
	"Penalty": "Denda",
	"Final Amount": "Jumlah Akhir",
	"1RT Payout": "Pembayaran 1RT",
	"Unsubscribe Lot Amount": "Jumlah Lot yang Dibatalkan",
	"Unsubscribe Lot": "Lot yang Dibatalkan",
	"Unsubscribe": "Membatalkan Langganan",
	"Month": "Bulan",
	"Year": "Tahun",
	"Total Point": "Total Poin",
	"Point": "Poin",
	"BNB Point Ratio": "Rasio Poin BNB",
	"Staking Amount": "Jumlah Staking",
	"BNB Received": "BNB Diterima",
}

export default {
	$vuetify: {
		...id
	},
	...nav,
	...starter_pack,
	...token_transaction,
	...royalty,
	...pairing_tree,
	...direct_sponsor,
	...date,
	...home,
	...user,
	...misc,
	...user_token,
	...burning_royalty,
	...staking
}