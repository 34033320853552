import axios from 'axios';

let prefix = "/app-feature-flag"

const AppFeatureFlagApi = {
	
	getAppFeatureFlagList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default AppFeatureFlagApi;