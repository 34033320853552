<template>
	<svg width="30" height="29" 
		viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" 
			clip-rule="evenodd" d="M4.6005 5.76387L12.74 0.337279C13.8908 -0.42995 15.4543 0.174508 15.7897 1.51631L15.8015 1.56333L8.80087 5.76387H4.6005ZM22.2157 1.9047L23.502 5.76356H11.601L19.2369 0.854793C20.3346 0.149132 21.8031 0.666713 22.2157 1.9047ZM3.75 7.81394C1.67893 7.81394 0 9.49288 0 11.5639V25.1656C0 27.2367 1.67893 28.9156 3.75 28.9156H22.9522C25.0233 28.9156 26.7022 27.2367 26.7022 25.1656V23.3152H27.0023V21.8652H27.0025C28.549 21.8652 29.8027 20.6115 29.8027 19.0649C29.8027 17.5184 28.549 16.2647 27.0025 16.2647H27.0023V14.8146H26.7022V14.5953V11.5639C26.7022 9.49287 25.0233 7.81394 22.9522 7.81394H3.75ZM26.7022 14.8146H24.9696H22.3016C20.2305 14.8146 18.5516 16.4935 18.5516 18.5646V19.5652C18.5516 21.6362 20.2305 23.3152 22.3016 23.3152H26.7022V22.1344V21.8652H22.8021C22.6207 21.8652 22.4433 21.8479 22.2715 21.815C21.0523 21.799 20.067 20.8133 20.0518 19.5938C20.019 19.4226 20.0019 19.2458 20.0019 19.0649C20.0019 18.8841 20.019 18.7073 20.0518 18.536C20.0669 17.3163 21.0527 16.3303 22.2723 16.3147C22.4439 16.2819 22.621 16.2647 22.8021 16.2647H26.7022V14.8146ZM10.3706 13.634C10.6635 13.3411 11.1384 13.3411 11.4313 13.634L15.6316 17.8344C15.9245 18.1273 15.9245 18.6021 15.6316 18.895C15.3387 19.1879 14.8639 19.1879 14.571 18.895L11.6509 15.975V22.5651C11.6509 22.9793 11.3151 23.3151 10.9009 23.3151C10.4867 23.3151 10.1509 22.9793 10.1509 22.5651V15.975L7.2309 18.895C6.938 19.1879 6.46313 19.1879 6.17024 18.895C5.87734 18.6021 5.87734 18.1273 6.17024 17.8344L10.3706 13.634Z" fill="black"/>
	</svg>
</template>

<script>
export default {
	name: "transactionFill"
};
</script>

<style>

</style>